import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import WelcomeLogo from "../../shared/welcome-logo/WelcomeLogo";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import CardMessage from "../../shared/cards/CardMessage";
// import Background from "../../../components/shared/background/Background";

import { CustomTextInput, CustomCheckbox } from "../../shared/forms/FormInputs";

function Signup() {
  const { registerUser, userRegState } = useContext(AuthContext);
  const [error, setError] = useState({ status: false, message: "" });
  const [onSuccess, setOnSuccess] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [register, setRegister] = useState<registerData>({});

  const userRegister = async (values: any) => {
    await registerUser(values);
  };

  interface registerData {
    confirmPassword?: string;
    email?: string;
    name?: string;
    password?: string;
    termsAndCondtion?: boolean;
  }

  useEffect(() => {
    if (userRegState.message) {
      //document.getElementById("reset-form")?.click();
      setError({ status: true, message: userRegState.message });
      setButtonState(false);
    }

    if (userRegState.type === "REGISTER_SUCCESS") {
      setOnSuccess(true);
      setButtonState(false);
    }
  }, [userRegState]);

  useEffect(() => {
    setError({ status: false, message: "" });
    setTimeout(() => {}, 10000);
  }, [onSuccess]);

  useEffect(() => {
    localStorage.removeItem("guest");
    setOnSuccess(false);
    setError({ status: false, message: "" });
  }, []);

  const onFocus = () => {
    setError({ status: false, message: "" });
  };

  return (
    <div>
      {/* <Background /> */}
      <div className="body-container">
        <WelcomeLogo />
        <div className="form-container">
          {error.status ? (
            <CardMessage title="Error" message={error.message} />
          ) : null}

          {onSuccess ? (
            <div>
              <CardMessage
                icon="success"
                title="Success"
                message="Please check your email to activate your account."
                btn={{ name: "Sign in", path: "/sign-in" }}
              />
            </div>
          ) : null}
          <Formik
            initialValues={{
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
              termsAndCondtion: false,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .min(3, "Name must be at least 3 characters")
                .required("Name is required")
                .trim(),
              email: Yup.string()
                .email("Email is invalid")
                .required("Email is required")
                .trim(),
              password: Yup.string()
                .min(6, "Password must be at least 6 characters")
                .required("Password is required")
                .trim(),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), ""], "Passwords must match")
                .required("Confirm Password is required")
                .trim(),
              termsAndCondtion: Yup.boolean()
                .required("Required")
                .oneOf([true], "You must accept the terms and conditions"),
            })}
            onSubmit={(values, actions) => {
              setButtonState(true);
              if (Object.keys(register).length === 0) {
                userRegister(values);
                setRegister(values);
              } else if (register.email === values.email) {
                setButtonState(false);
              } else {
                setError({ status: false, message: "" });
                userRegister(values);
                setRegister(values);
              }
            }}
          >
            {(props) => (
              <div>
                <Form className={`  ${onSuccess ? "hide" : "show"}`}>
                  <div>
                    <CustomTextInput
                      label="Name"
                      name="name"
                      placeholder="Name"
                      onFocus={onFocus}
                      type="text"
                    />
                  </div>
                  <div>
                    <CustomTextInput
                      label="Email"
                      name="email"
                      placeholder="Email"
                      onFocus={onFocus}
                      type="email"
                    />
                  </div>
                  <div>
                    <CustomTextInput
                      label="Password"
                      name="password"
                      placeholder="Password"
                      onFocus={onFocus}
                      type="password"
                    />
                  </div>
                  <div>
                    <CustomTextInput
                      label="Password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onFocus={onFocus}
                      type="password"
                    />
                  </div>

                  <div className="mb-10">
                    <CustomCheckbox
                      name="termsAndCondtion"
                      className="checkbox-container"
                    >
                      Creating an account means you’re okay with our{" "}
                      <Link
                        className="text-blue text-d-none font-weight-bold"
                        to="/terms-and-conditions"
                        target="_blank"
                      >
                        Terms of Service
                      </Link>
                      ,{" "}
                      <Link
                        className="text-blue text-d-none font-weight-bold"
                        to="/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                      , and our default Notification Settings.
                    </CustomCheckbox>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="curve-btn yellow-gradient"
                      disabled={buttonState}
                    >
                      Sign Up
                    </button>
                    <button
                      type="reset"
                      id="reset-form"
                      style={{ display: "none" }}
                    >
                      Reset
                    </button>
                    <p>
                      Already registered?{" "}
                      <Link to="/sign-in" className="text-blue text-d-none">
                        Sign in here
                      </Link>
                    </p>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Signup;
