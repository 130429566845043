import React, { useState, useEffect } from "react";
// import { AuthContext } from "../../context/AuthContext"; useContext
// import { ThemeContext } from "../../context/ThemeContext";
import { getRequestIp } from "../../../services/request-ip";

function PlayCanv(props: any) {
  const [renderState, setRenderState] = useState(false);
  const [showRedirect, showRedirectWindow] = useState(false);
  const [redirectRoute, setRedirect] = useState("");
  const [iframeAttributes, setIframeAttributes] = useState(
    `${process.env.REACT_APP_PLAYCANV_IFRAME}`
  );

  // useEffect(() => {
  //   let search = window.location.search;
  //   let params = new URLSearchParams(search);
  //   if(params && params.get('startPage')){
  //     let updatedAttribute = {...iframeAttributes};
  //     updatedAttribute.src = `${process.env.REACT_APP_PLAYCANV_IFRAME}?startPage=${params.get('startPage')}`
  //     setIframeAttributes(updatedAttribute);
  //   }
  // }, [renderState])

  useEffect(() => {
    getRequestIp()
      .then((results) => {
        const getToken = localStorage.getItem("token");

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let iframeSRC = process.env.REACT_APP_PLAYCANV_IFRAME
          ? process.env.REACT_APP_PLAYCANV_IFRAME +
            "?random=" +
            new Date().getTime() +
            Math.floor(Math.random() * 1000000)
          : "";

        if (params && params.get("startPage")) {
          console.log("adfsdf");

          iframeSRC = `${
            process.env.REACT_APP_PLAYCANV_IFRAME
          }?startPage=${params.get("startPage")}&random=${
            new Date().getTime() + Math.floor(Math.random() * 1000000)
          }`;
        }

        setIframeAttributes(iframeSRC);

        setRenderState(true);
        const postMessageData = {
          token: getToken,
          serverUrl: window.location.origin,
          requestIp: results.data.requestIp || "",
        };
        var eventMethod = window.addEventListener
          ? "addEventListener"
          : "attachEvent";
        var eventer = window["addEventListener"];
        var messageEvent =
          eventMethod === "attachEvent" ? "onmessage" : "message";
        eventer(messageEvent, function (e: any) {
          let baseUrl = new URL(iframeSRC ? iframeSRC : "");
          // console.log("message received from child......", e.data);
          if (e.origin !== baseUrl.origin) return;
          const childMessage = e.data;
          if (childMessage && childMessage.loadCompleted) {
            const elementNew: HTMLIFrameElement = document.getElementById(
              "iFrame"
            ) as HTMLIFrameElement;
            if (elementNew !== null && elementNew.contentWindow !== null) {
              return elementNew.contentWindow.postMessage(
                postMessageData,
                iframeSRC ? iframeSRC : ""
              );
            }
          }

          if (childMessage && childMessage.redirectTo === "/childrens") {
            window.location.href = childMessage.redirectTo;
            return false;
          } else if (childMessage && childMessage.redirectTo) {
            if (childMessage.redirectTo === "/hasbro") {
              localStorage.setItem(
                "wi-boolean",
                childMessage.userWifiConnected
              );
            }
            setRedirect(childMessage.redirectTo);
            setTimeout(function () {
              document.location.href = childMessage.redirectTo;
            }, 250);
            // window.location.href = childMessage.redirectTo;
            // const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
            // if (isSafari) {
            //   setRedirect(childMessage.redirectTo);
            //   setTimeout(function(){document.location.href = childMessage.redirectTo;},250);
            //   // showRedirectWindow(true);
            // } else {
            //   window.open(
            //     childMessage.redirectTo,
            //     '_blank' // <- This is what makes it open in a new window.
            //   );
            // }
            // window.open(
            //   childMessage.redirectTo,
            //   '_blank' // <- This is what makes it open in a new window.
            // );
            return false;
          }
          if (childMessage && childMessage.logout) {
            localStorage.clear();
            window.location.href = "/";
          }
        });
      })
      .catch((error) => {
        setRenderState(true);
      });
  }, []);

  return (
    // <div>
    <>
      {renderState ? (
        <iframe
          id="iFrame"
          title="playcanv"
          src={iframeAttributes}
          className="iframe"
          allow={`camera ${process.env.REACT_APP_PLAYCANV_IFRAME}`}
        ></iframe>
      ) : null}
      {showRedirect && (
        <div className="popup-overlay">
          <div className="popup-container p-3 m-2">
            {/* <img src={error} alt="Delete Child" /> */}
            {/* <h2 className="text-blue mtb-0">You will be redirected</h2> */}
            <p className="mb-3">
              To view this content you will be redirected to our platform page
            </p>
            <div>
              <button
                className="col-11 col-sm-6 col-md-5 curve-btn btn-delete mb-10 no-shawdow"
                type="button"
                onClick={(e) => {
                  window.location.href = redirectRoute;
                }}
              >
                Yes, I'd like to
              </button>
            </div>
            <div>
              <button
                className="col-11 col-sm-6 col-md-5 curve-btn btn-back mb-10 no-shawdow"
                type="button"
                onClick={() => showRedirectWindow(false)}
              >
                Stay here
              </button>
            </div>
          </div>
        </div>
      )}
    </>
    // </div>
  );
}

export default PlayCanv;
