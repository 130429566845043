import React from "react";
import { Link } from "react-router-dom";
import { history } from "../../../utils/history";

// import WelcomeLogo from "../../../components/shared/welcome-logo/WelcomeLogo";
import alderplaylogo from "../../../assets/image/alderplaylogo.png";
import "../StaticPages.scss"


function ErrorHandler() {
    console.log("history", history.location.pathname);
    let content;
    if (history.location.pathname === "/internal-server-error") {
        content = {
            heading: "500",
            body: "An internal server error occurred"
        }
    } else if (history.location.pathname === "/forbidden") {
        content = {
            heading: "403",
            body: "Forbidden"
        }
    } else {
        content = {
            heading: "404",
            body: "Sorry this page cannot be found"
        }
    }

    return (
        <header>
            {/* <WelcomeLogo /> */}
            <div className="Background">
                <div className="error-wrapper">
                    <div className="error-container">
                        <div className="hero-container">
                            <Link to="/">
                                <img alt="alderplaylogo" src={alderplaylogo} />
                            </Link>
                        </div>
                        <h1>{content.heading}</h1>
                        <h3>OOPS!</h3>
                        <p style={{ padding: "0px 10px 10px 10px", marginBottom: "30px" }}>{content.body}</p>
                        <div>
                            <Link to="/">
                                <button className="curve-btn btn-secondary" type="button">Back to home page</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default ErrorHandler;