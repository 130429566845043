import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
import ProfileLogo from "../../../components/shared/profile-logo/ProfileLogo";
import CardMessage from "../../../components/shared/cards/CardMessage";
import {
  CustomDatepicker,
  CustomTextInput,
} from "../../../components/shared/forms/FormInputs";
import { useParams } from "react-router-dom";
import { ChildrenContext } from "../../../context/ChildrenContext";
import { ThemeContext } from "../../../context/ThemeContext";
import Skins from "./Skins";
import moment from "moment";

function EditChildren() {
  const [error] = useState({ status: false, message: "" });
  const { childrens, updateChild } = useContext(ChildrenContext);
  const [childProfile, setChildProfile] = useState({});
  const [editChildrenStatus, setEditChildrenStatus] = useState(false);
  const { id } = useParams();
  const { skin } = useContext(ThemeContext);
  let [userDetails, setuserDetails] = useState<any>({});
  let [isSkinShow, setValSkin] = useState(false);
  const [dobState, setDobState] = useState<any>(null);

  useEffect(() => {
    let currentChild = childrens.filter(
      (child: any) => child.id === parseInt(id)
    );
    if (currentChild.length > 0) {
      let intialData = {
        name: currentChild[0].name,
        nickname: currentChild[0].nickname,
        hometown: currentChild[0].hometown,
        dob: new Date(currentChild[0].dob),
        favFood: currentChild[0].favFood,
        favColour: currentChild[0].favColour,
        favMovie: currentChild[0].favMovie,
        favAnimal: currentChild[0].favAnimal,
        currentTheme: currentChild[0].currentTheme,
      };
      setDobState(intialData.dob);
      setChildProfile(intialData);
      setEditChildrenStatus(true);
    }
  }, [id, childrens]);

  interface childDetails {
    name: string;
    nickname: string;
    hometown: string;
    dob: Date;
    favFood: string;
    favColour: string;
    favMovie: string;
    favAnimal: string;
    currentTheme: string;
  }

  let intialData = childProfile as childDetails;

  let changeHandler = (value: string) => {
    if (value === "submit") {
      userDetails.currentTheme = skin;
      userDetails.nickname = 'null';
      userDetails.hometown = 'null';
      userDetails.favFood = 'null';
      userDetails.favColour = 'null';
      userDetails.favMovie = 'null';
      userDetails.favAnimal = 'null';
      updateChild({ userDetails, id });
    } else if (value === "back") {
      setChildProfile(userDetails);
      setValSkin((isSkinShow = false));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <header>
      <div onClick={() => window.history.back()}>
        <div className="back-button"></div>
      </div>
      {isSkinShow ? (
        <Skins onChange={changeHandler} skinFor="Edit" />
      ) : (
        <>
          <div className="body-container">
            <ProfileLogo />
            {editChildrenStatus ? (
              <Formik
                initialValues={intialData}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .min(3, "Name must be at least 3 characters")
                    .required("Name is required")
                    .trim(),
                  dob: Yup.date().required("Date of birth is required"),
                  nickname: Yup.string()
                    .required("Nickname is required")
                    .trim(),
                  hometown: Yup.string()
                    .required("Hometown is required")
                    .trim(),
                  favColour: Yup.string().required("Color is required").trim(),
                  favFood: Yup.string().required("Food is required").trim(),
                  favMovie: Yup.string().required("Movie is required").trim(),
                  favAnimal: Yup.string().required("Animal is required").trim(),
                })}
                onSubmit={(values) => {
                  let modifyDate = { ...values };
                  modifyDate.dob = new Date(
                    moment(values.dob).format("YYYY-MM-DD")
                  );
                  console.log(modifyDate);

                  setuserDetails((userDetails = modifyDate));
                  setValSkin((isSkinShow = true));
                }}
              >
                {(props) => (
                  <Form className="form-container form-orientation stpos">
                    {error.status ? (
                      <CardMessage title="Error" message={error.message} />
                    ) : (
                      ""
                    )}

                    <div className="fc-grid">
                      <div>
                        <div className="text-label"><label htmlFor=""> Name</label></div>
                        <CustomTextInput
                          label="Name"
                          name="name"
                          placeholder="First name and surname"
                          type="text"
                        />
                      </div>
                      <div className="ori-mt-20">
                        <div className="text-label"><label htmlFor=""> Date of birth</label></div>
                        <CustomDatepicker
                          name="dob"
                          selected={dobState}
                          maxDate={new Date()}
                        />
                      </div>

                      {/* <div>
                        <CustomTextInput
                          label="Name"
                          name="nickname"
                          placeholder="Nickname"
                          type="text"
                        />
                      </div>
                      <div>
                        <CustomTextInput
                          label="My Hometown"
                          name="hometown"
                          placeholder="My Hometown"
                          type="text"
                        />
                      </div>

                      <div>
                        <CustomTextInput
                          label="My favourite colour"
                          name="favColour"
                          placeholder="My favourite colour"
                          type="text"
                        />
                      </div> */}

                      {/* <DatePicker
                          selected={dobState}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                          showMonthDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          fixedHeight
                          popperPlacement="top-end"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "5px, 0px"
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport"
                            }
                          }}
                          className="curve-field mb-10"
                          name="dob"
                          autoComplete="off"
                          placeholderText="My date of birth (MM/DD/YYYY)"
                          minDate={new Date(dateObject.year - 100, dateObject.month, dateObject.day)}
                          maxDate={new Date()}
                          onChange={(date: Date) => {
                            if (date) {
                              try {
                                setDobState(date);
                                intialData.dob = date;
                              } catch (exception) {
                                console.log("exception", exception);
                              }
                            }
                          }}
                        /> */}

                      {/* <div>
                        <CustomTextInput
                          label="My favourite food"
                          name="favFood"
                          placeholder="My favourite food"
                          type="text"
                        />
                      </div>

                      <div>
                        <CustomTextInput
                          label="My favourite movie"
                          name="favMovie"
                          placeholder="My favourite movie"
                          type="text"
                        />
                      </div>

                      <div>
                        <CustomTextInput
                          label="My favourite animal"
                          name="favAnimal"
                          placeholder="My favourite animal"
                          type="text"
                        />
                      </div> */}
                    </div>
                    <div className="height-30"></div>
                    <div className="form-group">
                      <button type="submit" className="curve-btn btn-primary">
                        Save
                      </button>
                      <button
                        type="reset"
                        id="reset-form"
                        style={{ display: "none" }}
                      >
                        Reset
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </header>
  );
}

export default EditChildren;
