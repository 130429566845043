import React from "react";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { baseEnv } from "../../environments/base";

function ImageUploader(props: any) {

    const { imageURL } = props;

    const getUploadParams = ({ file, meta }: any) => {
        const body = new FormData()
        body.append('image', file)
        return { url: `${baseEnv.api.uploadImage}`, body }
    }

    const handleChangeStatus = ({ meta, remove, xhr }: any, status: any) => {
        console.log(status);

        if (status === 'done') {
            let response = JSON.parse(xhr.response);
            imageURL(response.data.blobURL)
        }
        if (status === 'removed') {
            imageURL()
        }
    }

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            multiple={false}
            canCancel={true}
            inputContent="Choose Image"
            accept=".jpeg,.png,.PNG,.jpg,.JPG,.JPEG"
            maxSizeBytes={1048576}//1 MB
            styles={{
                dropzoneActive: { borderColor: 'green' },
            }}
        />
    );
}

export default ImageUploader;
