import React, { createContext, useReducer, useEffect } from "react";
import { ThemeContextModel } from "../models/child.model"
import AppReducer from "../stores/reducers/AppReducer";
import SkinThemes from "../utils/SkinThemes";

//Theme Skin Intial State

const intialSkin = localStorage.getItem("skin")
  ? localStorage.getItem("skin")
  : "skinA";
const intialBgHide = false;


// Create Context
export const ThemeContext = createContext({} as ThemeContextModel);

//Provider Component

export const ThemeProvider = ({ children }: any) => {
  const [state, dispath] = useReducer(AppReducer, { skin: intialSkin });
  const [bgData, dispathBg] = useReducer(AppReducer, { removeBg: intialBgHide });

  //Actions
  function changeTheme(id: string) {
    dispath({
      type: "CHANGE_THEME",
      payload: id,
    });
  }

  function setBgis(val: string) {
    dispathBg({
      type: "BG_STATE",
      payload: val,
    });
  }

  useEffect(() => {
    setTheme(state.skin);
  }, [state.skin]);

  function setTheme(id: string) {
    try {
      const theme = SkinThemes[id];
      Object.keys(theme).forEach((key) => {
        const cssKey = `--${key}`;
        const cssValue = theme[key];
        document.body.style.setProperty(cssKey, cssValue);
      });
    } catch (error) {
      console.log('error', error);
      localStorage.setItem("skin", "skinA");
    }
  }

  return (
    <ThemeContext.Provider
      value={{
        skin: state.skin,
        showBg: bgData.removeBg,
        changeTheme: (props: string) => changeTheme(props),
        setTheme: (props: string) => setTheme(props),
        setBgis: (props: string) => setBgis(props),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
