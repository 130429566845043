import React from "react";
import { Link } from "react-router-dom";

function LandingScreen() {
  return (
    <div className="d-flex flex-column h-100vh">
      <h1 className="landing-header m-0">PLEASE SELECT A PLATFORM</h1>
      <div className="row mx-0 w-100 justify-content-center">

        {localStorage.getItem("userRole") !== 'clinician' && (
        <div className="mt-3 mx-md-4 landing-card"> 
          <Link className="text-decoration-none" to="/childrens">
            <div className="p-5 text-center br-30px">
              <h2 className="landing-card-block">ALDERPLAY</h2>
            </div>
          </Link>
        </div>
        )}

        <div className="mt-3 mx-md-4 landing-card">
          <Link className="text-decoration-none" to="/asone/dashboard">
            <div className="p-5 text-center br-30px">
              <h2 className="landing-card-block">As One</h2>
            </div>
          </Link>
        </div>
        <div className="mt-3 mx-md-4 landing-card">
          <Link className="text-decoration-none" to="/anywhere/dashboard">
            <div className="p-5 text-center br-30px">
              <h2 className="landing-card-block">Anywhere</h2>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LandingScreen;
