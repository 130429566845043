import React, { useEffect, useContext, useMemo } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import "./scss/Common.scss";
import { ThemeProvider } from "./context/ThemeContext";
import { TinaProvider, TinaCMS } from "tinacms";
import { AuthProvider } from "./context/AuthContext";
import { ChildrenProvider } from "./context/ChildrenContext";
import Background from "./components/shared/background/Background";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import TinaAuthContext, {
  TinaAuthContextProvider,
} from "./context/TinaAuthContext";
import { history } from "./utils/history";
import helperFunctions from "./utils/HelperService";
import { loginRequest } from "./authConfig";

import { callMsGraph } from "./graph";

if (process.env.REACT_APP_GA_TRACKING_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GA_TRACKING_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

function App() {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  });

  const isAuthenticated = helperFunctions.isAuthenticatedAdmin();

  const { toggleTinaAdminAuthenticated, changeTinaAdminUserData } =
    useContext(TinaAuthContext);

  const cms = new TinaCMS({
    enabled: true,
    toolbar: true,
    // media: new DummyMediaStore(),
  });

  const TinaWidget = {
    __type: "toolbar:widget",
    name: "howdy",
    weight: 5,
    component: TinaDashboard,
  };

  const memoizedTina = useMemo(() => cms, []);
  const memoizedTinaWidget = useMemo(() => TinaWidget, []);

  function Logout() {
    memoizedTina.disable();
    localStorage.clear();
    toggleTinaAdminAuthenticated();
    changeTinaAdminUserData();
    window.location.href = "/";
  }

  function TinaDashboard() {
    return (
      <>
        <button type="button" onClick={() => history.push("/")}>
          Home
        </button>
        <button type="button" onClick={Logout}>
          Logout
        </button>
      </>
    );
  }

  memoizedTina.plugins.add(memoizedTinaWidget);
  if (!isAuthenticated) {
    memoizedTina.disable();
  }

  return (
    <TinaAuthContextProvider>
      <TinaProvider cms={memoizedTina}>
        <ThemeProvider>

          <AuthProvider>
            <ChildrenProvider>
              <Background />
            </ChildrenProvider>
          </AuthProvider>
        </ThemeProvider>
      </TinaProvider>
    </TinaAuthContextProvider>
  );
}

export default App;
