import React from "react";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { baseEnv } from "../../environments/base";

function VideoUploader(props: any) {
  const { videoURL } = props;

  const getUploadParams = ({ file, meta }: any) => {
    const body = new FormData();
    body.append("video", file);
    return { url: `${baseEnv.api.uploadVideo}`, body };
  };

  const handleChangeStatus = (
    { meta, file, remove, xhr }: any,
    status: any
  ) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      videoURL(response.data.blobURL);
      // setVideo(['upload'])
    } else if (status === "removed") {
      // setVideo(['upload', 'url'])
    } else if (status === "uploading") {
      // setVideo(['upload'])
    }
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      canCancel={true}
      inputContent="Choose Video"
      accept=".mp4,.MP4,.mov,.MOV,.webm,.WEBM"
      maxSizeBytes={262144000} //200Mb
      styles={{
        dropzoneActive: { borderColor: "green" },
      }}
    />
  );
}

export default VideoUploader;
