import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import WelcomeLogo from "../../shared/welcome-logo/WelcomeLogo";
import CardMessage from "../../shared/cards/CardMessage";
// import Background from "../../../components/shared/background/Background";


import { CustomTextInput } from "../../shared/forms/FormInputs";

function Login() {
  const { handleAuth, authState } = useContext(AuthContext);
  const [error, setError] = useState({ status: false, message: "" });

  const checkLogin = async (values: any) => {
    await handleAuth(values);
  };

  useEffect(() => {

    if (authState.message) {
      // document.getElementById("reset-form")?.click();
      if (authState.message)
        setError({ status: true, message: authState.message });
    }
  }, [authState]);

  useEffect(() => {
    window.scrollTo(0, 1)
    setError({ status: false, message: "" });
  }, []);

  const onFocus = () => {
    setError({ status: false, message: "" });
  };

  return (
    <div>
      {/* <Background /> */}
      <div className="body-container">
        <WelcomeLogo />

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid")
              .required("Email is required")
              .trim(),
            password: Yup.string()
              .min(6, "Password must be at least 6 characters")
              .required("Password is required")
              .trim(),
          })}
          onSubmit={(values) => {
            checkLogin(values);
          }}
        >
          {(props) => (

            <div className="form-container">
              {error.status ? (
                <CardMessage title="Error" message={error.message} />
              ) : (
                ""
              )}
              <Form>
                <div>
                  <CustomTextInput
                    label="Email"
                    name="email"
                    placeholder="Email"
                    onFocus={onFocus}
                    type="email"
                  />
                </div>
                <div>
                  <CustomTextInput
                    label="Password"
                    name="password"
                    placeholder="Password"
                    onFocus={onFocus}
                    type="password"
                  />
                </div>

                <div className="form-group">
                  <div className="mb-10">
                    <Link
                      to="/forgot-password"
                      className="text-d-none text-black"
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <button type="submit" className="curve-btn blue-gradient">
                    Sign in
                  </button>


                  <button
                    type="reset"
                    id="reset-form"
                    style={{ display: "none" }}
                  >
                    Reset
                  </button>
                  <p>
                    Not registered?{" "}
                    <Link
                      to="/age-verification"
                      className="text-blue text-d-none"
                    >
                      Sign up now
                    </Link>
                  </p>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
