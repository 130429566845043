export default (state: any, action: any) => {
  switch (action.type) {
    case "CHANGE_THEME":
      return {
        ...state,
        skin: action.payload,
      };
    case "BG_STATE":
      return {
        ...state,
        removeBg: action.payload,
      };
    default:
      return state;
  }
};
