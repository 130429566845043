import React from "react";

import { InlineTextarea } from "react-tinacms-inline";
import "../../styles/heading.css";

export interface H1Props {
  index: number;
}

const H1: React.SFC<H1Props> = (data: { index: number }) => {
  return (
    <div className="h1__wrapper">
      <div>
        <h2>
          <InlineTextarea name="text" focusRing={false} />
        </h2>
      </div>
    </div>
  );
};

export default H1;

export const headingBlock = {
  Component: H1,
  template: {
    label: "Heading",
    defaultItem: {
      text: "Page title",
    },
    fields: [
      {
        label: "Page Title",
        name: "Maia.about",
        component: "group-list",
        description: "Heading List",
        itemProps: (item: { id: any; name: any }) => ({
          key: item.id,
          label: item.name,
        }),
        defaultItem: () => ({
          name: "New Heading",
          id: Math.random().toString(36).substr(2, 9),
        }),
        fields: [],
      },
    ],
  },
};
