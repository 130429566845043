import { baseEnv } from "../environments/base";
import axios from "axios";
import { handleResponseStatus } from "./responseHandler";

export function getRequestIp() {
  return axios({
    method: "get",
    url: `${baseEnv.api.requestIp}`,
    headers: {
      Pragma: "no-cache",
    },
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}
