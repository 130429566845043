import React from "react";

import { HtmlFieldPlugin, MarkdownFieldPlugin } from "react-tinacms-editor";
import { InlineForm } from "react-tinacms-inline";
import { useForm, usePlugin, useCMS, usePlugins } from "tinacms";
import { getPage, updatePage } from "../page.services";

class saveGetApis {
  async saveData(formData: any) {
    try {
      const pathName = window.location.pathname.slice(1);
      const updatedpagesData = await updatePage({
        slug: pathName || "landing",
        pageData: formData,
      });
      console.log("\n updatedpagesData...", updatedpagesData);
    } catch (error) {
      console.log("\n save data error...", error);
    }
  }

  async getData() {
    try {
      let pathName = window.location.pathname.slice(1);
      const pagesDataResp = await getPage(pathName || "landing");
      console.log(pagesDataResp);
      return pagesDataResp.data.pageData || {};
    } catch (error) {
      console.log("\n get data error...", error);
      return {};
    }
  }
}

export default function TinaConfig(props: {
  children: any;
  modifiedFormValues?: (formValue: { [key: string]: any }) => {
    [key: string]: any;
  };
}) {
  const cms = useCMS();
  const formConfig = {
    id: "tina form config",
    async loadInitialValues() {
      const value = await cms.api.apiFunc.getData();
      const modifiedValue =
        (props.modifiedFormValues && props.modifiedFormValues(value)) || {};
      return { ...value, ...modifiedValue };
    },
    label: "hey hi",
    async onSubmit(formData: any) {
      await cms.api.apiFunc.saveData(formData);
      await cms.alerts.success("Saved!");
    },
  };

  const [, form] = useForm(formConfig);

  cms.registerApi("apiFunc", new saveGetApis());
  usePlugins([HtmlFieldPlugin, MarkdownFieldPlugin]);
  usePlugin(form);

  return (
    <div className="home">
      <InlineForm form={form}>{props.children}</InlineForm>
    </div>
  );
}
