import { userConstants } from "../../constants";

const userLocalVal = localStorage.getItem("user");
let user = userLocalVal ? JSON.parse(userLocalVal) : "";

export const initialState = {
  type: userConstants.LOGIN_SUCCESS,
  loading: false,
  loggedIn: true,
  user,
  message: "",
}

export function authentication(state = initialState, action: any) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        type: action.type,
        loggingIn: false,
        loading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        type: action.type,
        loading: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        type: action.type,
        loggedIn: false,
        loading: false,
        message: action.message,
      };
    case userConstants.LOGOUT:
      localStorage.clear();
      return {
        ...state
      };
    default:
      return state;
  }
}
