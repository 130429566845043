import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import WelcomeLogo from "../../shared/welcome-logo/WelcomeLogo";
import { Formik, Form } from "formik";
import * as UserServervices from "../../../services/user.services";
// import Background from "../../../components/shared/background/Background";
import CardMessage from "../../shared/cards/CardMessage";
import { CustomTextInput } from "../../shared/forms/FormInputs";
import { ResetPasswordModel } from "../../../models/user.model";

function ResetPassword(props: any) {
  const [onSuccess, setOnSuccess] = useState(false);
  const [tokenExpired, settokenExpired] = useState(false);
  const [error, setError] = useState({
    status: true,
    title: "New password",
    message: "Please enter your new password",
  });

  function getToken() {
    const urlParams =
      new RegExp("[?&]token=([^&#]*)").exec(window.location.href) || [];
    return urlParams.length > 0 ? urlParams[1] : null;
  }
  let token = getToken();

  const [resetToken] = useState(token);

  useEffect(() => {
    checkForgotToken(token ? token : "");
  }, [token]);

  const checkForgotToken = async (token: string) => {
    try {
      await UserServervices.checkForgotToken({ token })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          settokenExpired(true);
          setError({
            status: true,
            title: "Error",
            message: error,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = async (values: ResetPasswordModel) => {
    await UserServervices.resetPassword(values)
      .then((response) => {
        setOnSuccess(true);
        setError({
          status: false,
          title: "",
          message: "",
        });
      })
      .catch((error) => {
        setError({
          status: false,
          title: "Something Went Wrong",
          message: error.response.data.message,
        });
      });
  };

  return (
    <div>
      {/* <Background /> */}
      <div className="body-container">
        <WelcomeLogo />
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
            token: resetToken ? resetToken : "",
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(6, "Password must be at least 6 characters")
              .required("Password is required")
              .trim(),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("password"), ""], "Passwords must match")
              .required("Confirm Password is required")
              .trim(),
          })}
          onSubmit={(values: ResetPasswordModel) => {
            changePassword(values);
          }}
        >
          {(props) => (
            <div className="form-container">
              {error.status ? (
                <CardMessage title={error.title} message={error.message} />
              ) : null}
              {onSuccess ? (
                <div>
                  <CardMessage
                    icon="success"
                    title="Success"
                    btn={{ name: "Sign in", path: "/sign-in" }}
                  />
                </div>
              ) : null}
              <Form
                className={`  ${onSuccess || tokenExpired ? "hide" : "show"}`}
              >
                <div>
                  <CustomTextInput
                    label="Password"
                    name="password"
                    placeholder="Password"
                    type="password"
                  />
                </div>
                <div>
                  <CustomTextInput
                    label="Confirm Password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                  />
                </div>
                <div className="height-30"></div>
                <div className="form-group">
                  <button type="submit" className="curve-btn blue-gradient">
                    Confirm
                </button>
                  <p>
                    Not registered?
                  <Link to="/register" className="text-blue text-d-none">
                      Sign up now
                  </Link>
                  </p>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ResetPassword;
