import React, { useState, useEffect } from "react";
import * as UserServervices from "../../../services/user.services";
import CardMessage from "../../shared/cards/CardMessage";
// import Background from "../../components/shared/background/Background";
import WelcomeLogo from "../../shared/welcome-logo/WelcomeLogo";

function VerifyEmail() {
  const [status, setStatus] = useState({
    status: false,
    title: "",
    message: "",
    btn: {},
  });
  const [cardShow, setCardShow] = useState(false);

  function getToken() {
    const urlParams =
      new RegExp("[?&]token=([^&#]*)").exec(window.location.href) || [];
    return urlParams.length > 0 ? urlParams[1] : null;
  }
  
  let token = getToken();

  useEffect(() => {
    localStorage.clear();
    checkToken(token ? token : "");
  }, [token]);

  const checkToken = async (token: string) => {
    try {
      await UserServervices.verifyEmail({ token: token })
        .then((response) => {
          setStatus({
            status: false,
            title: "Success",
            message: "Your Email is verified successfully.",
            btn: {
              name: "Sign in",
              path: "/sign-in",
            },
          });
        })
        .catch((error) => {
          setStatus({
            status: false,
            title: "Error",
            message: error,
            btn: {
              name: "Go Back",
              path: "/",
            },
          });
        });
      setCardShow(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {/* <Background /> */}
      <WelcomeLogo />
      <div className="form-container">
        {cardShow ? (
          <CardMessage
            title={status.title}
            message={status.message}
            btn={status.btn}
          />
        ) : (
            ""
          )}
      </div>
    </>
  );
}

export default VerifyEmail;
