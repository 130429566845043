import { userConstants } from "../../constants";

export const initialState = {
  loading: false,
  user: null,
  message: "",
  profile: null,
};

export const passwordReset = {
  loading: false,
  password: null,
  message: "",
};

export function usersReducer(state = initialState, action: any) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        type: action.type,
        loading: true,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        type: action.type,
        user: action.user,
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        type: action.type,
        message: action.message,
      };
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        type: action.type,
        loading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        type: action.type,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        type: action.type,
        message: action.message,
      };
    default:
      return state;
  }
}
