import React from 'react';

import { BlocksControls } from 'react-tinacms-inline';
import '../../styles/paragraph.css';

export interface ParagraphProps {
  index: number;
  data: { Markdown: string };
}

const Paragraph: React.SFC<ParagraphProps> = (data: {
  index: number;
  data: { Markdown: string };
}) => {
  return <div dangerouslySetInnerHTML={{ __html: data.data.Markdown }} />;
};

export default Paragraph;

export const paragraphBlock = {
  Component: (data: { index: number; data: { Markdown: string } }) => (
    <BlocksControls index={data.index} focusRing={{ offset: 0 }} insetControls>
      <Paragraph {...data} />
    </BlocksControls>
  ),
  template: {
    label: 'Paragraph',
    defaultItem: {
      text: 'paragraph'
    },
    fields: [
      {
        name: 'Markdown',
        label: 'Markdown',
        component: 'html',
        widget: 'block'
      }
    ]
  }
};
