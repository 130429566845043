import React, { useEffect, useState } from "react";
import nhslogo from "../../../assets/image/alderhey-nhslogo.png";
import charity from "../../../assets/image/alder-hey-charity.png";
import shoplogo from "../../../assets/image/shop-direct-logo.png";
import liverpoologo from "../../../assets/image/liverpool-logo.png";
import nhsengland from "../../../assets/image/nhs-england.png";
import hasbrologo from "../../../assets/image/hasbro-logo.png";
import ustwologo from "../../../assets/image/ustwo-logo.png";
import lucylogo from "../../../assets/image/lucy-logo.png";
import hartreelogo from "../../../assets/image/hartree-logo.png";
import masklogo from "../../../assets/image/mask-logo.png";
import junglelogo from "../../../assets/image/jungle-logo.png";
import studiowidelogo from "../../../assets/image/studiowide-logo.png";
import factlogo from "../../../assets/image/fact-logo.png";
import mindwavelogo from "../../../assets/image/mindwave-logo.png";
import { getRequestIp } from "../../../services/request-ip";

const iframeUrl = `${process.env.REACT_APP_PLAYCANV_IFRAME}`;

function TermsConditions() {
  const [backBtn, setbackBtn] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backButton = async () => {
    await getRequestIp()
      .then((results) => {
        if (localStorage.getItem("token")) {
          window.location.href = `${iframeUrl}?token=${localStorage.getItem(
            "token"
          )}&environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${
            results.data.requestIp
          }&startPage=Grown%20ups%20Zone`;
        } else {
          window.location.href = `${iframeUrl}?environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${results.data.requestIp}&startPage=Grown%20ups%20Zone`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token") || localStorage.getItem("guest")) {
      setbackBtn(true);
    }
  }, []);

  return (
    <div className="static-Background">
      <div className="bg-header">
        {backBtn && (
          <div onClick={backButton}>
            <div className="back-button"></div>
          </div>
        )}
        <h2>Alder Play Terms and Conditions</h2>
      </div>
      <div className="body-container content-container">
        <h3>Alder Play Terms and Conditions</h3>
        <h5>Introduction</h5>
        <p>
          Welcome! Your access to and use of the Alder Play platform is subject
          to the following terms and conditions. We hope you enjoy AlderPlay. So
          that you may fully enjoy AlderPlay, please read these terms and
          conditions, which will govern your use of the platform. As user in
          this User Agreement, “you” and “your” refers to any user of the
          platform, and if you are a child, “you” and “your” includes your
          parent(s) and/or legal guardians(s). “We”, “our” and “us” refers to
          Alder Hey Living Hospital Ltd.
        </p>
        <h5>Acceptance of User Agreement</h5>
        <p>
          By accessing and/or using the platform, you specifically agree to be
          bound by these terms and conditions (this “User Agreement”), including
          the Privacy Policy which is incorporated in and made a part of this
          User Agreement. We reserve the right to update or otherwise modify
          this User Agreement at any time, in our sole and absolute discretion.
          If you do not agree to this User Agreement, please exit and stop using
          the platform.
        </p>
        <h5>Intellectual Property</h5>
        <p>
          All copyright, trademarks and all other intellectual property rights
          in all material or content supplied as part of the platform or Service
          shall remain at all times vested in us, our licensors or content
          contributors. You are permitted to use this material or content only
          within AlderPlay and as expressly authorised by us, our licensors or
          contributors.
        </p>
        <p>
          If you become aware of any unauthorised distribution or commercial
          exploitation of AlderPlay, please notify us at{" "}
          <a href="alderplay@alderhey.nhs.uk">alderplay@alderhey.nhs.uk.</a>
        </p>
        <p>You must not do (or allow to be done) any of the following:</p>
        <p>
          Copy (except for limited permissions to copy under the Copyright
          Designs and Patents Act 1988, as amended from time to time (the
          “Act”)), redistribute or relay the whole or any part of the platform
          or otherwise deal with the platform in a way which is not permitted
          under the Act; Sell or make any charge for watching or using any part
          of the platform; Show or re-transmit any part of the platform to the
          public, even if no charge is made;
          <strong>
            Use the platform for any improper or unlawful purpose;
          </strong>
          Tamper with or modify the platform in any way; copy or redistribute
          any of the reward badges, so as to share and make available to the
          public.
        </p>
        <h5>Third-Party Websites</h5>
        <p>
          You acknowledge and agree that we are not responsible for the
          availability of any third party websites or material you access
          through Alder Play . This includes, but is not limited to, the ‘Arti’
          chatbot, which is an add-on to the main Alder Play platform and uses
          artificial intelligence (AI) to provide instant answers to questions
          submitted by users of the platform. For this reason, the chatbot
          should be considered an experimental feature. We do not endorse and
          shall not be held responsible or liable for any content, advertising,
          products or services on or available from such websites or material.
        </p>
        <h5>No Warranties; Limitation of Liability; Disclaimer of Damages</h5>
        <p>
          In no event shall we, respective licensors or content contributors be
          liable to you, or anyone claiming through you, for (1) Incidental,
          indirect, special or consequential loss or damages or injury, or (2)
          any damages whatsoever resulting from loss of use or inability to use
          AlderPlayor the content accessible through the platform.
        </p>
        <h5>Feedback</h5>
        <p>
          If you provide feedback or ideas (“suggestions”) on AlderPlay, you
          understand we may use your suggestions without any obligation to
          attribute them to you or to compensate you. You must not give us any
          information that is confidential, commercially sensitive or contains
          original creative work or is the intellectual property or proprietary
          property of you or another person.
        </p>
        <h6>Alder Play Sponsors</h6>
        <p>
          We would like to thank the following sponsor for making Alder Play a
          reality:
        </p>

        <div className="image-container">
          <div className="image-block">
            <img alt="Terms and conditions" src={nhslogo} />
            <p>Alder Hey Children’s NHS Foundation Trust</p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={charity} />
            <p>Alder Hey Children’s Charity</p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={shoplogo} />
            <p>Shop Direct</p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={liverpoologo} />
            <p>Liverpool John Lennon Airport</p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={nhsengland} />
            <p>NHS England</p>
          </div>
          <div className="text-left">
            <h5>Alder Play Content Contributors</h5>
            <p>
              We would like to thank the following content contributors who have
              helped to enrich Alder Play and in-hospital experience:
            </p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={hasbrologo} />
            <p>Hasbro Video content</p>
            <p>
              Transformers, My Little Pony, Littlest Pet Shop, The Adventures of
              Chuck and Friends
            </p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={ustwologo} />
            <p>Sound effects David Morgan & 3D Characters Grant Liddall</p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={lucylogo} />
            <p>Alder Hey App Characters</p>
            <p>“The Critters”</p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={hartreelogo} />
            <p>ArtiChatbot</p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={masklogo} />
            <p>Consultancy Services</p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={junglelogo} />
            <p>Jungle Interactive</p>
            <p>The Hardleeys of Alder Hey Jigsaw and Matching Pairs Games</p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={studiowidelogo} />
            <p>
              360° Video and Photography Explore Alder Hey Images by
              studiowide.co.uk
            </p>
          </div>
          <div className="image-block">
            <img alt="Terms and conditions" src={factlogo} />
            <p>Dawn Chorus</p>
            <p>
              Wild Song at Dawn (2007) Chris Watson with patients from Alder Hey
              Children’s Hospital, commissioned and produced by FACT
            </p>
          </div>
          <div className="image-block">
            <p>Twin Vision</p>
            <p>
              {" "}
              BEDLAM! An animation by patients from Alder Hey Children's
              Hospital
            </p>
          </div>
          <div className="image-block">
            <p>Mindwave Ventures</p>
            <p> Design Development and Platform Architecture</p>
            <img
              style={{
                width: "100%",
                maxWidth: "500px",
                marginTop: "20px",
              }}
              alt="Mindwave Logo"
              src={mindwavelogo}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
