import React, { useContext, useState, useEffect } from "react";
import "./Children.scss";
import "../../../scss/components/SlickSlider.scss";
import "../../../scss/components/Popup.scss";
import Slider from "react-slick";
import ProfileLogo from "../../../components/shared/profile-logo/ProfileLogo";
import Circle from "../../../assets/image/circle.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import error from "../../../assets/image/error.png";
import { ChildrenContext } from "../../../context/ChildrenContext";
import { ThemeContext } from "../../../context/ThemeContext";
import * as ChildServervices from "../../../services/children.services";
import { getRequestIp } from "../../../services/request-ip";
// const [iframeUrl] = useState(`${process.env.REACT_APP_PLAYCANV_IFRAME}`);
const iframeUrl = `${process.env.REACT_APP_PLAYCANV_IFRAME}`;
function ListChildren() {
  const { childrens, deleteChild, getChilds } = useContext(ChildrenContext);
  const { changeTheme, setTheme } = useContext(ThemeContext);
  const { logout } = useContext(AuthContext);
  let [selectedChild, setSelectedChild] = useState(Number);
  const [selectedChildSkin, setSelectedChildSkin] = useState(String);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteActiveUser, setDeleteActiveUser] = useState(true);

  console.log("iframe Url........", process.env);
  const deleteChildren = () => {
    deleteChild(selectedChild);
    let elems = document.querySelectorAll(".circle-block");
    elems.forEach((el) => el.classList.remove("active"));
    setDeletePopup(false);
    setSelectedChildSkin("");

    childrens.forEach((child: any, index: number) => {
      if (child.isActive) {
        activeChildHighlighter(index);
      }
    });
  };

  function activeChildHighlighter(id: number) {
    var elems = document.querySelectorAll(".circle-block");

    elems.forEach((el) => el.classList.remove("active"));

    elems[id].classList.add("active");
  }

  useEffect(() => {
    let currentChild = childrens.filter((child: any) => child.isActive);
    if (currentChild.length > 0) {
      let activeChild = currentChild[0];
      if (!selectedChildSkin) {
        setSelectedChildSkin(activeChild.currentTheme);
        localStorage.setItem("skin", activeChild.currentTheme);
      }
      if (!selectedChild) {
        setSelectedChild(activeChild.id);
      }
    }
  }, [childrens, selectedChild, selectedChildSkin]);

  async function switchChildren() {
    await ChildServervices.switchChildren(selectedChild)
      .then(async (response) => {
        console.log(
          "switch children response",
          response,
          `${iframeUrl}?token=${localStorage.getItem("token")}&environment=${process.env.REACT_APP_CUSTOM_NODE_ENV
          }`
        );
        await getRequestIp()
          .then((results) => {
            console.log(results);
            window.location.href = `${iframeUrl}?token=${localStorage.getItem(
              "token"
            )}&environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${results.data.requestIp
              }`;
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);
  var settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

   useEffect(() => {
    getChilds()
  }, []);

  return (
    <div>
      <div className="body-container">
      <div onClick={() => window.history.back()}>
          <div className="back-button"></div>
        </div>
        <ProfileLogo />
        <div className="profile-container profile-circle ">
          <div className="container-profile profile-con profile_style">
            <Slider {...settings}>
              {childrens.map((child: any, i: number) => (
                <div
                  key={child.id}
                  className={`circle-block  ${child.isActive ? "active" : ""}`}
                  onClick={(e) => {
                    setSelectedChild(child.id);
                    setSelectedChildSkin(child.currentTheme);
                    activeChildHighlighter(i);
                    setDeleteActiveUser(child.isActive ? true : false);
                    setTheme(child.currentTheme);
                    changeTheme(child.currentTheme);
                  }}
                >
                  {child.isActive && !selectedChildSkin
                    ? setTheme(child.currentTheme)
                    : ""}
                  <div className="circle-card">
                    <img className="profile-img" src={Circle} alt="Profile" />
                    <h3 className="cp-name">{child.name}</h3>
                  </div>
                </div>
              ))}
              <div className="circle-block profile-add">
                <Link to="/childrens/add">
                  <div
                    className="circle-card"
                    onClick={() => {
                      setTheme("skinA");
                      changeTheme("skinA");
                      localStorage.setItem("skin", "skinA");
                    }}
                  >
                    <img className="profile-img" src={Circle} alt="Profile" />
                    <h3 className="cp-name line_name">Add new profile </h3>
                  </div>
                </Link>
              </div>
            </Slider>
          </div>
        </div>
        <form className="form-container form-relative">
          <div className=" mb-10"></div>

          <div className="parent_btn">
            {selectedChild === 0 ? (
              <>
                <div>
                  <Link to={`/childrens/`}>
                    <button
                      className="curve-btn btn-secondary mb-10 "
                      type="button"
                    >
                      Enter
                    </button>
                  </Link>
                </div>
                <div>
                  <Link to={`/childrens/`}>
                    <button
                      className="curve-btn btn-primary mb-10 "
                      type="button"
                    >
                      Edit Profile
                    </button>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div>
                  {/* <Link to={`/childrens/gameProfile/${selectedChild}`}> */}
                  <button
                    className="curve-btn btn-secondary mb-10 "
                    type="button"
                    onClick={() => switchChildren()}
                  >
                    Enter
                  </button>
                  {/* </Link> */}
                </div>
                <div>
                  <Link to={`/childrens/profile/edit/${selectedChild}`}>
                    <button
                      className="curve-btn btn-primary mb-10 "
                      type="button"
                      onClick={() => {
                        changeTheme(selectedChildSkin);
                        localStorage.setItem("skin", selectedChildSkin);
                      }}
                    >
                      Edit Profile
                    </button>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="mb-10 parent_btn_1">
            <div>
              <span  className="text-d-none action link-a "
              onClick={() => setDeletePopup(true)}>Delete profile
                </span>
            </div>
            <div>
              <span className="text-d-none action link-a "
              onClick={() => {
                logout();
                setTheme("skinA");
              }}>Logout
                </span>
            </div>
          </div>
        </form>
        {deletePopup ? (
          deleteActiveUser ? (
            <div className="popup-overlay">
              <div className="popup-container">
                <img src={error} alt="Delete Child" />
                <p>
                  The active profile of a child cannot be deleted. Please switch
                  the active child
                </p>
                <div>
                  <button
                    className="curve-btn btn-back mb-10 no-shawdow"
                    type="button"
                    onClick={() => setDeletePopup(false)}
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="popup-overlay">
              <div className="popup-container">
                <img src={error} alt="Delete Child" />
                <h2 className="text-blue mtb-0">Are you sure?</h2>
                <p>
                  Deleting this profile will be permanent and will remove all
                  the profile information, including stickers, skins and profile
                  settings. Are you sure you want to delete this profile?
                </p>
                <div>
                  <button
                    className="curve-btn btn-delete mb-10 no-shawdow"
                    type="button"
                    onClick={deleteChildren}
                  >
                    Delete account
                  </button>
                </div>
                <div>
                  <button
                    className="curve-btn btn-back mb-10 no-shawdow"
                    type="button"
                    onClick={() => setDeletePopup(false)}
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}

export default ListChildren;
