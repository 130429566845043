import React from 'react'
import profilelogo from '../../../assets/image/profile.png';

function profile_logo() {
    return (
        <div className="hero-container">
        <img src={profilelogo} alt="profilelogo"/>
    </div>
    )
}

export default profile_logo
