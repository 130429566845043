import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import CardMessage from "../../shared/cards/CardMessage";
import WelcomeLogo from "../../shared/welcome-logo/WelcomeLogo";
import { getRequestIp } from "../../../services/request-ip";
const iframeUrl = `${process.env.REACT_APP_PLAYCANV_IFRAME}`;

function GuestUser() {
  const { changeTheme, setTheme } = useContext(ThemeContext);
  const viewGameAsGuest = async () => {
    await getRequestIp()
      .then((results) => {
        localStorage.setItem("guest", "1");
        console.log(
          `${iframeUrl}?environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${results.data.requestIp}`
        );
        window.location.href = `${iframeUrl}?environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${results.data.requestIp}`;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <header>
      <div className="body-container">
        <WelcomeLogo />
        <div className="form-container guest-popup">
          <CardMessage message="Press enter to access Alder play as a guest. If you would like to save your progress,choose a buddy and customise the look press go back and create an account." />
          <div className="height-30"></div>
          <div>
            {/* <Link to="/childrens/profileGuest"> */}
            <button
              className="curve-btn blue-gradient mb-10 no-shawdow"
              type="button"
              onClick={() => {
                setTheme("skinA");
                changeTheme("skinA");
                localStorage.setItem("skin", "skinA");
                viewGameAsGuest();
              }}
            >
              Enter
            </button>
            {/* </Link> */}
          </div>
          <div>
            <Link to="/">
              <button
                className="curve-btn yellow-gradient mb-10 no-shawdow"
                type="button"
              >
                Go back
              </button>
            </Link>
          </div>
          <p>
            Already registered?{" "}
            <Link to="/sign-in" className="text-blue text-d-none">
              Sign in here
            </Link>
          </p>
        </div>
      </div>
    </header>
  );
}
export default GuestUser;
