import React, { useContext, useEffect } from "react";
import "./Game.scss"
import PlayCanv from "./PlayCanv";
import { useParams } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { history } from "../../../utils/history";

function GameZone() {
  const { childrenID } = useParams();
  const { setBgis, showBg } = useContext(ThemeContext);

  useEffect(() => {
    if (!showBg) {
      setBgis(true)
    }
    //Handle browser back.
    window.onpopstate = () => {
      setBgis(false)
      history.push(history.location.pathname);
    }
  }, [setBgis, showBg])


  return (
    <div className="loading-wrapper">
      {showBg ?
        <PlayCanv children={childrenID} />
        : <div></div>
      }
    </div>
  );
}

export default GameZone;
