import React, { Suspense, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Age from "./components/account/age/Age";
import Home from "./pages/Home/home";
import Login from "./components/account/login/Login";
import Register from "./components/account/register/Register";
import ResetPassword from "./components/account/reset-password/ResetPassword";
import PublicRoute from "./routes/PublicRoute";
import { history } from "./utils/history";
import ForgotPassword from "./components/account/forgot-password/ForgotPassword";
import TermsConditions from "./pages/static-pages/terms-and-conditions/TermsConditions";
import PrivacyPolicy from "./pages/static-pages/privacy-policy/PrivacyPolicy";
import VerifyEmail from "./components/account/verify-email/VerifyEmail";
import AddChildren from "./components/childrens-profile/children/AddChildren";
import ListChildren from "./components/childrens-profile/children/ListChildren";
import PrivateRoute from "./routes/PrivateRoute";
import GameZone from "./components/childrens-profile/game/GameZone";
import EditChildren from "./components/childrens-profile/children/EditChildren";
import UserSuccess from "./components/childrens-profile/children/UserSuccess";
import GuestUser from "./components/account/guest/GuestUser";
import errorHandler from "./pages/static-pages/error-handler/ErrorHandler";
import HasbroPrivateRoute from "./routes/HasbroPrivateRoute";
import HasbroContent from "./components/childrens-profile/game/HasbroContent";
import Logout from "./components/account/login/Logout";
import TagManager from "react-gtm-module";
import PrivacyPolicyTina from "./pages/tina-pages/PrivacyPolicyTina";
import LandingScreen from "./pages/Home/LandingScreen";
import { addEvent } from './services';

const asOneRoutes = React.lazy(() => import("./asone/AsOneRoute"));
const anywhereRoutes = React.lazy(() => import("./anywhere/AnywhereRoute"));

export default function AppRouter() {
  const [formData, setFormData] = useState({
    date: new Date(),
    path: window.location.pathname
  });

  useEffect(() => {
    const unlisten = history.listen((currentLocation) => {
      const currentDate = new Date();
      addEvent([{
        page: formData.path,
        startTime: new Date(formData.date).getTime(),
        endTime: new Date(currentDate).getTime()
      }]);
      setFormData({
        date: currentDate,
        path: currentLocation.pathname
      });
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
        },
      });
    });
    return unlisten;
  }, []);

  return (
    <Router history={history}>
      <Suspense fallback={<h6>Loading…</h6>}>
        <Switch>
          <PublicRoute
            restricted={true}
            path="/register"
            component={Register}
          />
          <PublicRoute restricted={true} path="/sign-in" component={Login} />
          <PublicRoute
            restricted={true}
            path="/age-verification"
            component={Age}
          />
          <PublicRoute
            restricted={true}
            path="/guestUser"
            component={GuestUser}
          />
          <PublicRoute
            restricted={true}
            path="/verify-email"
            component={VerifyEmail}
          />
          <PublicRoute
            exact
            restricted={true}
            path="/reset-password"
            component={ResetPassword}
          />
          <PublicRoute exact path="/tina" component={PrivacyPolicyTina} />
          <PublicRoute
            restricted={true}
            path="/forgot-password"
            component={ForgotPassword}
          />

          <PublicRoute
            restricted={true}
            path="/childrens/profileGuest"
            component={GameZone}
          />
          <HasbroPrivateRoute path="/hasbro" component={HasbroContent} />
          <PrivateRoute exact path="/landing" component={LandingScreen} />
          <PrivateRoute path="/asone" component={asOneRoutes} />
          <PrivateRoute path="/anywhere" component={anywhereRoutes} />
          <PrivateRoute path="/blood-test" component={HasbroContent} />
          <PrivateRoute path="/coronavirus" component={HasbroContent} />
          <PrivateRoute path="/music" component={HasbroContent} />
          <PrivateRoute path="/ready-steady-go" component={HasbroContent} />
          <PrivateRoute path="/x-ray" component={HasbroContent} />
          <PrivateRoute path="/pick-n-mix" component={HasbroContent} />
          <PrivateRoute path="/pick-n-mix" component={HasbroContent} />
          <Route path="/terms-and-conditions" component={TermsConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/logout" component={Logout} />

          <PrivateRoute
            path="/childrens/profile/edit/:id"
            component={EditChildren}
          />
          <PrivateRoute
            path="/userSuccess/:childName"
            component={UserSuccess}
          />
          <PrivateRoute
            path="/childrens/gameProfile/:childrenID"
            component={GameZone}
          />
          <PrivateRoute path="/childrens/add" component={AddChildren} />
          <PrivateRoute exat path="/childrens" component={ListChildren} />

          <PublicRoute exact restricted={true} path="/" component={Home} />
          <PublicRoute
            restricted={false}
            path="/internal-server-error"
            component={errorHandler}
          />
          <PublicRoute
            restricted={false}
            path="/forbidden"
            component={errorHandler}
          />
          <Route path="*" component={errorHandler} />
          {/* <PrivateRoute component={profile} path="/profile" exact /> */}
        </Switch>
      </Suspense>
    </Router>
  );
}
