import React, { useContext } from 'react';
import '../../scss/Common.scss';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import WelcomeLogo from '../../components/shared/welcome-logo/WelcomeLogo';
//import { ThemeContext } from "../../context/ThemeContext";
// import Background from "../../components/shared/background/Background";
import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from '../../components/SignInButton';
import { SignOutButton } from '../../components/SignOutButton';
import { getRequestIp } from '../../services/request-ip';
const iframeUrl = `${process.env.REACT_APP_PLAYCANV_IFRAME}`;

function Home() {
  const isAuthenticated = useIsAuthenticated();
  const { changeTheme, setTheme } = useContext(ThemeContext);
  const viewGameAsGuest = async () => {
    await getRequestIp()
      .then((results) => {
        localStorage.setItem('guest', '1');
        console.log(
          `${iframeUrl}?environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${results.data.requestIp}`
        );
        window.location.href = `${iframeUrl}?environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${results.data.requestIp}`;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <header>
      <div className="body-container">
        <WelcomeLogo />
        <div className="form-container">
          <div className="mb-3">
            <button
              className="curve-btn text-black yellow-gradient"
              type="button"
              onClick={() => {
                setTheme('skinA');
                changeTheme('skinA');
                localStorage.setItem('skin', 'skinA');
                viewGameAsGuest();
              }}
            >
              Enter
            </button>
          </div>
          <div className="mb-3">
            <Link to="/sign-in">
              <button className="curve-btn blue-gradient" type="button">
                Sign in
              </button>
            </Link>
          </div>
          <div className="">
            <Link to="/age-verification">
              <button className="curve-btn blue-gradient" type="button">
                Create my profile
              </button>
            </Link>
          </div>
          {/* <div className="mb-10">
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
          </div> */}

          {/* <p>
            Want to access without login?{" "}
            <Link to="/guestUser">Click Here</Link>
          </p> */}
        </div>
      </div>
    </header>
  );
}

export default Home;
