import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

function Logout() {
  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
    history.push("/sign-in");
  }, [history]);

  return <div></div>;
}

export default Logout;
