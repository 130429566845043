import React, { useContext, useState, useEffect } from "react";
import AppRouter from "../../../routes";
import skinDefault from "../../../assets/image/hills-bg.png";
import { ThemeContext } from "../../../context/ThemeContext";
import "../Shared.scss";
import { useLocation } from "react-router-dom";
import { history } from "../../../utils/history";

function Background(props: any) {
  const { showBg } = useContext(ThemeContext);
  const [removeBg, setRemoveBg] = useState(false);

  // default style for IE
  let defaultBackground = {
    backgroundImage: `url(${skinDefault})`,
  };
  let isIe = localStorage.getItem("isIE");

  useEffect(() => {
    // history.listen((location, action) => {
    if (history.location.pathname.includes("asone")) {
      setRemoveBg(true);
    } else {
      setRemoveBg(false);
    }
    // });
  }, [history.location]);

  history.listen((location, action) => {
    if (history.location.pathname.includes("asone")) {
      setRemoveBg(true);
    } else {
      setRemoveBg(false);
    }
  });

  return (
    <>
      {showBg ? (
        <AppRouter />
      ) : (
        <div
          className={`${!removeBg && "Background"}`}
          style={isIe === "true" ? defaultBackground : {}}
        >
          <div className="body-wrapper">
            <AppRouter />
          </div>
        </div>
      )}
    </>
  );
}

export default Background;
