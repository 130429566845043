import React from "react";
import SuccessIMG from "../../../assets/image/success-icon.png";
import { Link } from "react-router-dom";

function CardMessage(props: any) {
  return (
    <>
      <div className="text-center">
        <div className="message-box mb-10">
          {props.icon === "success" ? <img src={SuccessIMG} alt="" /> : null}

          {props.title ? (
            <h2 className="text-blue mtb-0">{props.title}</h2>
          ) : (
              ""
            )}

          <p className="mtb-0">{props.message}</p>
        </div>
        {props.btn ? (
          <>
            <div className="height-30"></div>
            <Link to={props.btn.path}>
              <button className="curve-btn btn-secondary" type="button">
                {props.btn.name}
              </button>
            </Link>
          </>
        ) : null}
      </div>
    </>
  );
}

export default CardMessage;
