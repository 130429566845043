import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

interface ToastOptions {
  position?:
  | "top-right"
  | "top-center"
  | "top-left"
  | "bottom-right"
  | "bottom-center"
  | "bottom-left";
  autoClose?: number | false | undefined;
  hideProgressBar?: boolean | undefined;
  closeOnClick?: boolean | undefined;
  pauseOnHover?: boolean | undefined;
  draggable?: boolean | undefined;
  progress?: string | number | undefined;
}

const helperFunctions = {
  showToast: (
    messageToShow: string,
    toastType: "success" | "info" | "error" | "warning" | "default" | "dark",
    toasterOptions?: ToastOptions
  ) => {
    const toasterOptionsModified = toasterOptions;
    if (toasterOptionsModified && !toasterOptionsModified.position) {
      toasterOptionsModified.position = "top-right";
    }
    if (toasterOptionsModified && !toasterOptionsModified.autoClose) {
      toasterOptionsModified.autoClose = 2000;
    }
    if (toasterOptionsModified && !toasterOptionsModified.pauseOnHover) {
      toasterOptionsModified.pauseOnHover = false;
      toasterOptionsModified.hideProgressBar = false;
    }
    toast.dismiss();
    switch (toastType) {
      case "success":
        toast.success(messageToShow, toasterOptionsModified);
        break;
      case "error":
        toast.error(messageToShow, toasterOptionsModified);
        break;
      case "info":
        toast.info(messageToShow, toasterOptionsModified);
        break;
      case "warning":
        toast.warn(messageToShow, toasterOptionsModified);
        break;
      case "dark":
        toast.dark(messageToShow, toasterOptionsModified);
        break;
      case "default":
        toast(messageToShow, toasterOptionsModified);
        break;
    }
  },
  isAuthenticated: () => {
    const tokenString = localStorage.getItem("token");
    return !!tokenString;
  },
  isAuthenticatedAdmin: () => {
    const tokenString = localStorage.getItem("token");
    const role = localStorage.getItem("userRole") == 'admin' ? localStorage.getItem("userRole") : '';
    return !!(tokenString && role);
  },
  getAdminUserData: () => {
    try {
      const decoded_jwt: any = jwtDecode(sessionStorage.getItem("token") || "");
      if (decoded_jwt) {
        return { username: decoded_jwt.username, _id: decoded_jwt._id };
      }
      return { username: "", _id: "" };
    } catch (error) {
      return { username: "", _id: "" };
    }
  },
};

export default helperFunctions;
