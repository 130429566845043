/*
 * Base is the default environment.
 */
let baseApi;

if (process.env.NODE_ENV === "production") {
  baseApi = window.location.origin;
} else {
  baseApi = "https://staging.alderplay.mindwave.site";
}

export function headerToken() {
  const token = localStorage.getItem("token");
  return "Bearer " + token;
}

export const baseEnv = {
  api: {
    login: `${baseApi}/login`,
    register: `${baseApi}/signup`,
    adLogin: `${baseApi}/ad-login`,
    check: `${baseApi}/account/check`,
    userInfo: `${baseApi}/get-users`,
    resetPassword: `${baseApi}/reset-password`,
    forgotPassword: `${baseApi}/send-forgot-password-email`,
    checkForgotPassToken: `${baseApi}/check-forgot-token-expire`,
    verifyEmail: `${baseApi}/verify-email`,
    getChildrens: `${baseApi}/get-children`, //Childrens Api Start
    addChildren: `${baseApi}/add-children`, //Childrens Api Start
    deleteChildren: `${baseApi}/remove-children/`, //Childrens Api Start
    updateChildren: `${baseApi}/update-children-profile/`, //Childrens Api Start
    switchChildren: `${baseApi}/switch-children/`, //Childrens Api Start
    requestIp: `${baseApi}/requestIp`, //get the ip of the client
    uploadImage: `${baseApi}/upload-img`, //Upload image endpoint
    uploadVideo: `${baseApi}/upload-video`, //Upload video endpoint
    addVideo: `${baseApi}/add-video`, //Upload video endpoint
    updateVideo: `${baseApi}/update-video`, //Upload video endpoint
    deleteVideo: `${baseApi}/delete-video`, //Upload video endpoint
    getVideoCategory: `${baseApi}/get-video-category`, //Upload video endpoint
    getTinaPage: `${baseApi}/get-page`, //Upload video endpoint
    updateTinaPage: `${baseApi}/update-page`, //Upload video endpoint
    getPatientData: `${baseApi}/get-patient-data`, //get Patient data from Azure FHIR
    addUserEvent: `${baseApi}/user-analytic` // To add Event for user activity
  },
};
