import React from "react";
import { InlineBlocks } from "react-tinacms-inline";
import {
  paragraphBlock,
  headingBlock,
  h3Block,
} from "../../components/tinacms";
// import { getRequestIp } from "../../services/request-ip";
import TinaConfig from "../../services/tinacms/TinaConfig";

function PrivacyPolicyTina() {
  return (
    <TinaConfig>
      <div className="static-Background">
        <div className="bg-header">
          <InlineBlocks name="blocks_heading" blocks={BLOCKS_HEADING} />
        </div>
        <div className="body-container content-container">
          <InlineBlocks name="blocks_content" blocks={BLOCKS_CONTENT} />
        </div>
      </div>
    </TinaConfig>
  );
}

export default PrivacyPolicyTina;

const BLOCKS_HEADING = {
  heading: headingBlock,
};

const BLOCKS_CONTENT = {
  h3: h3Block,
  paragraph: paragraphBlock,
};
