import React, { createContext, useContext, useEffect, useReducer } from "react";
//services
import * as childrenService from "../services/children.services";

import { history } from "../utils/history";
import { AuthContext } from "./AuthContext";
import { isLogin } from "../utils/Auth";

import {
  ChildrenDetailsAddModel,
  ChildrenDetailsUpdateModel,
  ChildListArrayModel,
} from "../models/child.model";

import * as childrenReducer from "../stores/reducers/childrens.reducer";
import * as childrenActions from "../stores/actions/children.action";

//Create Children Context
export const ChildrenContext = createContext({} as any);

export const ChildrenProvider = ({ children }: any) => {
  const { authState } = useContext(AuthContext);
  const [state, dispatchChildren] = useReducer(
    childrenReducer.childReducer,
    childrenReducer.initialState
  );

  //Function
  function getChildrens() {
    console.log("get childrens");

    childrenService
      .getChildrens()
      .then((children) => {
        console.log("children", children);

        if (children.children.length < 1) history.push("/childrens/add");
        dispatchChildren(childrenActions.getChild(children));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function deleteChildren(id: string) {
    childrenService
      .deleteChildren(id)
      .then((response) => {
        dispatchChildren({
          type: "DELETE_CHILDREN",
          payload: id,
        });
        childrenService.getChildrens().then((children: ChildListArrayModel) => {
          console.log("children.children", children.children.length);
          if (children.children.length) {
            history.push("/childrens");
          } else {
            localStorage.setItem("skin", "skinA");
            history.push("/childrens/add");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function setHasbroFlager(params: ChildrenDetailsAddModel) {
    dispatchChildren({
      type: "HASBRO_CHILDREN",
      payload: params,
    });
  }

  function createChildren(props: any) {
    // console.log(props);

    childrenService
      .createChildren(props)
      .then((response) => {
        getChildrens();
        history.push("/userSuccess/add-" + props.name);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updateChildren(props: any) {
    childrenService
      .updateChildren(props)
      .then((response) => {
        getChildrens();
        let updateChildValues = { ...props.userDetails };
        updateChildValues.id = parseInt(props.id);
        dispatchChildren(childrenActions.updateChild(updateChildValues));
        history.push("/userSuccess/update-" + props.userDetails.name);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (isLogin() && !localStorage.getItem("userRole")) {
      getChildrens();
    } else {
      dispatchChildren(childrenReducer.initialState);
    }
  }, [authState]);

  return (
    <ChildrenContext.Provider
      value={{
        childrens: state.childrens,
        hasbroFlag: state.hasbroFlag,
        setHasbroFlag: (props: any) => setHasbroFlager(props),
        createChild: (props: ChildrenDetailsAddModel) => createChildren(props),
        deleteChild: (props: string) => deleteChildren(props),
        updateChild: (props: ChildrenDetailsUpdateModel) =>
          updateChildren(props),
        getChilds: () => getChildrens(),
      }}
    >
      {children}
    </ChildrenContext.Provider>
  );
};
