import React, { useEffect, useState } from "react";
import { getRequestIp } from "../../../services/request-ip";

const iframeUrl = `${process.env.REACT_APP_PLAYCANV_IFRAME}`;

function PrivacyPolicy() {
  const [backBtn, setbackBtn] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const backButton = async () => {
    await getRequestIp()
      .then((results) => {
        if (localStorage.getItem("token")) {
          window.location.href = `${iframeUrl}?token=${localStorage.getItem(
            "token"
          )}&environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${
            results.data.requestIp
          }&startPage=Grown%20ups%20Zone`;
        } else {
          window.location.href = `${iframeUrl}?environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${results.data.requestIp}&startPage=Grown%20ups%20Zone`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token") || localStorage.getItem("guest")) {
      setbackBtn(true);
    }
  }, []);

  return (
    <div className="static-Background">
      <div className="bg-header">
        {backBtn && (
          <div onClick={backButton}>
            <div className="back-button"></div>
          </div>
        )}

        <h2>Alder Play Privacy Policy</h2>
      </div>
      <div className="body-container content-container">
        <h3>Alder Play Privacy Policy</h3>
        <p>
          Living Hospital Limited takes your privacy seriously. This Alder Play
          privacy notice is intended to help parents and patients to understand
          what is being collected by the Alder Play platform, why we collect it,
          how it is used and who we may share it with.
        </p>
        <h5>Who are we?</h5>
        <p>
          The Alder Play Platform is the property of Alder Hey Living Hospital
          Ltd (company number 10735638), a wholly-owned subsidiary of Alder Hey
          Children’s Charity (charity number 1160661).
        </p>
        <h5>What information is being collected?</h5>
        <p>
          We will collect an email address and ask you to create a password
          associated with it. This allows us to create a unique and secure
          account for each patient which can be accessed each time you use the
          platform. It will also enable us to upload previous content saved and
          personalise your experience of the platform, based on preferences
          selected within it. The Alder Play platform will collect information
          based on your use of the platform. It will store rewards collected and
          preferences selected.
        </p>
        <h5>How is information being collected?</h5>
        <p>
          Information will be collected via the initial registration screen and
          preferences via the platform. Anything collected will be stored in
          compliance with Data Protection Regulations, GDPR and on servers based
          in the UK.
        </p>
        <h5>How will information be used?</h5>
        <p>
          Your email address collected at registration will be used to create
          your own unique account. The information collected via the platform
          will be used to personalise parts of the platform for you i.e choose a
          character and nickname. We will never use your information to email
          you.
        </p>
        <h5>Who will information be shared with?</h5>
        <p>
          The information you enter, and your use of the platform may be shared
          with third party processors. Agreements are in place with processors
          to ensure privacy regulations are adhered to.
        </p>
        <h5>How long will it be kept?</h5>
        <p>
          We will hold any information you give us under UK Data Protection
          laws. We will delete this at your request, or when there is no longer
          a need to hold it to support the operation and development of the
          platform.
        </p>
        <h5>Can I change my mind?</h5>
        <p>
          Of course! If you wish to opt out of using our platform or any
          services you have signed up to with Living Hospital Limited, delete
          the platform from your device. To request that we delete your
          information, contact us at{" "}
          <a href="mailto:alderplay@alderhey.nhs.uk">
            alderplay@alderhey.nhs.uk
          </a>
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
