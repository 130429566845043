import React, { useContext, useEffect, useState } from "react";
import "../../../scss/Common.scss";
import { useParams, Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import CardMessage from "../../shared/cards/CardMessage";
import WelcomeLogo from "../../shared/welcome-logo/WelcomeLogo";

function UserSuccess() {
  const { childName } = useParams();
  const [childDetails, setChildDetails] = useState("");
  const { changeTheme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (childName) {
      var name = childName.split("-");
      setChildDetails(name);
    }
  }, [setChildDetails, childName]);
  return (
    <header>
      <div className="body-container">
        <WelcomeLogo />
        <div className="form-container stpos text-blue user-success">
          <CardMessage
            icon="success"
            title="Success !"
            message={childDetails[0] === "add" ? `You've created a profile for ${childDetails[1]}` : `You've updated this profile for ${childDetails[1]}`}
          />
          <div>
            <div className="height-30"></div>
            {childDetails[0] === "add" ?
              <Link to="/childrens/add">
                <button
                  className="curve-btn btn-secondary mb-10 no-shawdow"
                  type="button"
                  onClick={() => {
                    setTheme("skinA");
                    changeTheme("skinA");
                    localStorage.setItem("skin", "skinA")
                  }}
                >
                  Create another child profile
              </button>
              </Link>
              : ""}
          </div>
          <div>
            <Link to="/childrens">
              <button
                className="curve-btn btn-primary mb-10 no-shawdow"
                type="button"
              >
                Go back
              </button>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
export default UserSuccess;
