import React, { useContext, useState, useRef } from "react";
import "../../../scss/Common.scss"
import Slider from "react-slick";
import { ThemeContext } from "../../../context/ThemeContext";
import WelcomeLogo from "../../shared/welcome-logo/WelcomeLogo";
import { SkinList } from "../../../constants/skins.constants";

function Skins(props: any) {
  const { skin, changeTheme, setTheme } = useContext(ThemeContext);
  let [userSkin, setUserSkin] = useState(skin);
  const [buttonState, setButtonState] = useState(false);

  let prevCountRef = useRef(skin);

  let changeHandler = (e: any) => {
    const type = e.target.name;
    if (type === "submit") {
      setButtonState(true);
      if (props.skinFor === 'Edit') {
        changeTheme(userSkin);
        prevCountRef.current = userSkin;
        props.onChange(type);
      } else {
        props.onChange(type, userSkin);
      }
    } else if (type === "back") {
      changeTheme(prevCountRef.current);
      if (props.skinFor === 'Edit') {
        props.onChange(type);
      } else {
        props.onChange(type, prevCountRef.current);
      }
    }
  };


  var settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2.5,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <>
      <div className="body-container">
        <WelcomeLogo />
        <div className="height-30"></div>
        <div className="skin-container">
          <div className="container profile-con">
            <Slider {...settings}>
              {SkinList.map((item, key) => (
                <div
                  className="card-block skins-card"
                  key={key}
                  onClick={() => {
                    if (props.skinFor === 'Edit') {
                      changeTheme(item.id);
                    } else {
                      setTheme(item.id);
                    }
                    setUserSkin((userSkin = item.id));
                  }}
                >
                  <div
                    className={
                      userSkin === item.id
                        ? "skin-active skin-card"
                        : userSkin === "skinC"
                          ? "skin-border skin-card"
                          : "skin-card"
                    }
                  >
                    <h3>{item.name}</h3>
                    <img src={item.imgSrc} alt={item.name} />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <form className="form-container stpos dp-mt">
          <div className=" mb-10"></div>
          <div>
            <button
              className="curve-btn btn-primary mb-10"
              type="button"
              name="submit"
              onClick={changeHandler}
              disabled={buttonState}
            >
              Choose skin
            </button>
          </div>
          <div>
            <button
              className="curve-btn btn-secondary mb-10"
              type="button"
              name="back"
              onClick={changeHandler}
            >
              Go Back
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Skins;
