import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
import ProfileLogo from "../../../components/shared/profile-logo/ProfileLogo";
import CardMessage from "../../../components/shared/cards/CardMessage";
import { ChildrenContext } from "../../../context/ChildrenContext";
import {
  CustomDatepicker,
  CustomTextInput,
} from "../../../components/shared/forms/FormInputs";
import { ThemeContext } from "../../../context/ThemeContext";
import Skins from "./Skins";

function AddChildren() {
  const [error] = useState({ status: false, message: "" });
  const { createChild } = useContext(ChildrenContext);
  const { skin, changeTheme, setTheme } = useContext(ThemeContext);
  let [isSkinShow, setValSkin] = useState(false);
  // const [dobState, setDobState] = useState<any>(null);
  const [childProfile, setChildProfile] = useState<any>({
    name: "",
    // nickname: "",
    // hometown: "",
    // favFood: "",
    dob: "",
    // favColour: "",
    // favMovie: "",
    // favAnimal: "",
    currentTheme: skin,
  });

  let changeHandler = (value: string, userSkin: string) => {
    if (value === "submit") {
      childProfile.currentTheme = userSkin;
      childProfile.nickname = 'null';
      childProfile.hometown = 'null';
      childProfile.favFood = 'null';
      childProfile.favColour = 'null';
      childProfile.favMovie = 'null';
      childProfile.favAnimal = 'null';
      changeTheme(skin);
      createChild(childProfile);
    } else if (value === "back") {
      childProfile.currentTheme = userSkin;
      setTheme(skin);
      setChildProfile(childProfile);
      setValSkin((isSkinShow = false));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <header>
      <div onClick={() => window.history.back()}>
        <div className="back-button"></div>
      </div>
      {isSkinShow ? (
        <Skins onChange={changeHandler} skinFor="Add" />
      ) : (
        <>
          <div className="body-container">
            <ProfileLogo />
            <Formik
              initialValues={childProfile}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .min(3, "Name must be at least 3 characters")
                  .required("Name is required")
                  .trim(),
                dob: Yup.date().required("Nickname is required"),
                // nickname: Yup.string().required("Nickname is required").trim(),
                // hometown: Yup.string().required("Hometown is required").trim(),
                // favColour: Yup.string().required("Color is required").trim(),
                // favFood: Yup.string().required("Food is required").trim(),
                // favMovie: Yup.string().required("Movie is required").trim(),
                // favAnimal: Yup.string().required("Animal is required").trim(),
              })}
              onSubmit={(values) => {
                setChildProfile(values);
                setValSkin((isSkinShow = true));
              }}
            >
              {(props) => (
                <Form className="form-container form-orientation stpos">
                  {error.status ? (
                    <CardMessage title="Error" message={error.message} />
                  ) : (
                    ""
                  )}

                  <div className="fc-grid">
                    <div>
                      <div className="text-label"><label htmlFor=""> Name</label></div>
                      <CustomTextInput
                        label="Name"
                        name="name"
                        placeholder="Enter your name"
                        type="text"

                      />
                    </div>
                    <div className="">
                      <div className="text-label"><label htmlFor=""> Date of birth</label></div>
                      <CustomDatepicker name="dob" maxDate={new Date()} />
                    </div>
                  </div>

                  <div className="height-30"></div>
                  <div className="form-group">
                    <button type="submit" className="curve-btn yellow-gradient">
                      Save
                    </button>
                    <button
                      type="reset"
                      id="reset-form"
                      style={{ display: "none" }}
                    >
                      Reset
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </header>
  );
}

export default AddChildren;
