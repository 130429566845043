import React, { forwardRef } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";

export const CustomTextInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <>

      <label htmlFor={props.id || props.name}></label>
      <input
        className="curve-field mb-10"
        {...field}
        {...props}
        autoComplete="off"
      />
      {meta.touched && meta.error ? (
        <div className="error-box">{meta.error}</div>
      ) : null}
    </>
  );
};

export const CustomCheckbox = ({ children, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <>
      {meta.touched && meta.error ? (
        <div className="error-box">{meta.error}</div>
      ) : null}
      <label className={` ${props.className ? props.className : ""}`}>
        <input type="checkbox" {...field} {...props} />
        <span className="checkmark"></span>
        {children}
      </label>
    </>
  );
};

export const CustomDatepicker = ({ children, ...props }: any) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  let pickerOpen=false;
  return (
    <div className="abc" onClick={()=>{document.getElementById('dateInputPicker')?.blur();  }}>
      <DatePicker
        {...field}
        {...props}
        // readOnly
        className="curve-field mb-10"
        dateFormat="dd/MM/yyyy"
        dropdownMode="select"
        showYearDropdown
        showMonthDropdown
        placeholderText="My date of birth (MM/DD/YYYY)"
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        id="dateInputPicker"
        onClick={() => {
console.log('\n clicked...');
        }}
        autoComplete={"off"}
      />
      {" "}
      {meta.touched && meta.error ? (
        <div className="error-box">Date of birth is required</div>
      ) : null}
    </div>
  );
};
