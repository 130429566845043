import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../utils/Auth";
import { useIsAuthenticated } from "@azure/msal-react";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isADAuthenticated = useIsAuthenticated();

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? <Component {...props} /> : <Redirect to="/sign-in" />
      }
    />
  );
};

export default PrivateRoute;
