import { baseEnv, headerToken } from "../environments/base";
import axios from "axios";
import { handleResponseStatus } from "./responseHandler";
import { IPageInterface } from "../models/page.model";

export function getPage(slug: string) {
  return axios
    .get(`${baseEnv.api.getTinaPage}?slug=${slug}`, {
      headers: {
        Authorization: headerToken(),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function updatePage(payload: IPageInterface) {
  console.log("payload~~~~~~~~~~~~~~~", payload);
  return axios
    .put(`${baseEnv.api.updateTinaPage}`, payload, {
      headers: {
        Authorization: headerToken(),
      },
    })
    .then(handleResponse)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleResponseStatus(error.response);
      return Promise.reject(error.response.data.message);
    });
}

function handleResponse(response: any) {
  console.log(response);
  if (response.status === 401) {
    //logout();
  } else if (response.status === 200 && response.data.status === false) {
    const error =
      (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  } else {
    return response.data;
  }
}
