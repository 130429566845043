import { baseEnv, headerToken } from "../environments/base";
import axios from "axios";
import { handleResponseStatus } from "./responseHandler";
import { ChildrenDetailsAddModel, ChildrenDetailsUpdateModel } from "../models/child.model";
import Moment from 'moment';

export function getChildrens() {
  return axios({
    method: "get",
    url: `${baseEnv.api.getChildrens}`,
    headers: {
      Authorization: headerToken(),
      Pragma: 'no-cache'
    },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function deleteChildren(props: string) {
  return axios({
    method: "delete",
    url: `${baseEnv.api.deleteChildren}` + props,
    headers: {
      Authorization: headerToken(),
    },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function createChildren(props: ChildrenDetailsAddModel) {
  // console.log(props);
  let replaceDate = {...props}
  replaceDate.dob = new Date(Moment(replaceDate.dob).format("YYYY-MM-DD"));
  return axios
    .post(`${baseEnv.api.addChildren}`, replaceDate, {
      headers: {
        Authorization: headerToken(),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function updateChildren(props: ChildrenDetailsUpdateModel) {

  let replaceDate = {...props.userDetails}
  replaceDate.dob = new Date(Moment(replaceDate.dob).format("YYYY-MM-DD"));

  return axios
    .put(`${baseEnv.api.updateChildren}` + props.id, replaceDate, {
      headers: {
        Authorization: headerToken(),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function switchChildren(props: number) {
  return axios({
    method: "put",
    url: `${baseEnv.api.switchChildren}` + props,
    headers: {
      Authorization: headerToken(),
    },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}
