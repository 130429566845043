import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import WelcomeLogo from "../../../components/shared/welcome-logo/WelcomeLogo";
import CardMessage from "../../../components/shared/cards/CardMessage";
import * as UserServervices from "../../../services/user.services";
// import Background from "../../../components/shared/background/Background";
import { CustomTextInput } from "../../../components/shared/forms/FormInputs";

function ForgotPassword() {
  const [buttonState, setButtonState] = useState(false);

  //API CALL USER SERVICES
  const passwordReset = async (values: any) => {
    try {
      await UserServervices.forgotPassword(values)
        .then((response) => {
          setError({
            status: true,
            title: "",
            //message: response.message,
            message:
              "Password reset message was successfully sent to your email address.",
          });
        })
        .catch((error) => {
          setError({
            status: false,
            title: "Email does not exists",
            message: error.response.data.message,
          });
          setButtonState(false);
        });

      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  //const [success, setSuccess] = useState(false);

  const [error, setError] = useState({
    status: true,
    title: "Forgot your password?",
    message:
      "Enter the email address you used when you joined and we’ll send you instructions to reset your password.",
  });

  function resetForm() {
    document.getElementById("reset-form")?.click();
  }

  // function onFocus() {
  //   setError({ status: false, title: "", message: "" });
  // }

  return (
    <div>
      {/* <Background /> */}
      <div className="body-container">
      <WelcomeLogo />
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Email is invalid")
            .required("Email is required").trim(),
        })}
        onSubmit={(values) => {
          setButtonState(true);
          passwordReset(values);
          setTimeout(() => {
            setButtonState(false);
          }, 3000);
        }}
      >
        {(props) => (
          <Form className="form-container">
            <CardMessage title={error.title} message={error.message} />
            <CustomTextInput
              label="password"
              name="email"
              placeholder="Email"
              type="email"
            />
            <div className="height-30"></div>
            <div className="form-group">
              <button type="submit" className="curve-btn blue-gradient" disabled={buttonState}>
                Send password reset
              </button>
              <button type="reset" id="reset-form" style={{ display: "none" }}>
                Send password reset
              </button>
              <p>
                Not registered?{" "}
                <Link to="/age-verification" className="text-blue text-d-none">
                  Sign up now
                </Link>
              </p>
            </div>
          </Form>
        )}
      </Formik>
      </div>
    </div>
  );
}

export default ForgotPassword;
