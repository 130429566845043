import skinDefault from "../assets/image/skin-default.png";
import skinSkyline from "../assets/image/skin-skyline.png";
import skinBeach from "../assets/image/skin-beach.png";
import skinSpace from "../assets/image/skin-space.png";

export const SkinList = [
  {
    id: "skinA",
    name: "Hills",
    imgSrc: skinDefault,
  },
  {
    id: "skinB",
    name: "Skyline",
    imgSrc: skinSkyline,
  },
  {
    id: "skinC",
    name: "Beach",
    imgSrc: skinBeach,
  },
  {
    id: "skinD",
    name: "Space",
    imgSrc: skinSpace,
  },
];
