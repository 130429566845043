import React, { useState } from "react";

import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Arrowndown from "../../../assets/image/arrow_drop_down.png";
import Arrownup from "../../../assets/image/arrow_drop_up.png";
import { Link, useHistory } from "react-router-dom";
// import Background from "../../../components/shared/background/Background";
import CardMessage from "../../shared/cards/CardStatusMessage";
import WelcomeLogo from "../../shared/welcome-logo/WelcomeLogo";

function Age() {
  let [age, setAge] = useState(0);
  const [ageCheck, setAgeCheck] = useState(false);

  const history = useHistory();

  const [error, setError] = useState({
    status: false,
    title: "",
    message: "",
  });

  const incrementAge = () => {
    setAge(++age);
    document.getElementById("age")?.setAttribute("value", `${age}`);
  };

  const decrementAge = () => {
    if (age < 1) return false;
    let ageUpdate = age - 1;
    setAge(ageUpdate);
  };

  const onCheck = () => {
    if (age > 12 && age <= 99) {
      history.push("/register");
      setAgeCheck(false);
    } else {
      setAgeCheck(true);
      if (age > 99) {
        setError({
          status: true,
          title: "",
          message:
            "Invalid age. Please try again",
        });
      } else {
        setError({
          status: true,
          title: "",
          message:
            "You need to be 13 to register. Please ask an adult to help sign you up.",
        });
      }
    }
  };
  return (
    <div>
      {/* <Background /> */}
      <div className="body-container">
        <WelcomeLogo />

        <Formik
          initialValues={{
            age: "",
          }}
          validationSchema={Yup.object().shape({
            // .moreThan(1).lessThan(80)
            age: Yup.number()
              .min(18, "You must be at least 18 years")
              .max(60, "You must be at most 60 years")
          })}
          onSubmit={(values) => {
            // console.log(values);
          }}
        >
          {({ errors, status, touched }) => (
            <div className="form-container">
              {error.status ? (
                <CardMessage title={error.title} message={error.message} />
              ) : (
                  ""
                )}
              <Form>
                <div>
                  <div className={`${ageCheck ? "hide" : "show"}`}>
                    <ErrorMessage
                      name="age"
                      component="div"
                      className="error-box"
                    />
                    <div className=" message-box mb-10">
                      <h2 className="text-blue mtb-0">How old are you?</h2>
                      <div>
                        <img
                          src={Arrownup}
                          onClick={incrementAge}
                          alt="Increase Age"
                        />
                        <input
                          name="age"
                          id="age"
                          placeholder="0"
                          type="number"
                          min="0"
                          max="99"
                          className={
                            "age-box" +
                            (errors.age && touched.age ? "is-invalid" : "")
                          }
                          autoComplete="off"
                          value={age !== 0 ? age : ''}
                          onChange={(event: any) => {
                            // const reg = /^\d+$/;
                            // if (reg.test(event.target.value)) {
                            //   setAge(event.target.value)
                            // } else {
                            //   return;
                            if (isNaN(Number(event.target.value)) || Number(event.target.value) < 0 || Number(event.target.value) >= 100) {
                              return;
                            } else {
                              event.target.value = Number(event.target.value);
                              setAge(event.target.value);
                            }
                          }
                          }
                        />
                        <img
                          src={Arrowndown}
                          onClick={decrementAge}
                          alt="Decrease Age"
                        />
                      </div>
                    </div>
                    <div className="mb-20"></div>
                    {!error.status ? (
                      <>
                        {/* <div className="height-30"></div> */}
                        <div>
                          <button
                            className="curve-btn yellow-gradient"
                            type="submit"
                            onClick={onCheck}
                          >
                            Next
                      </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                  {error.status ? (
                    <>
                      <div className="height-30"></div>
                      <div>
                        <button
                          className="curve-btn yellow-gradient"
                          type="button"
                          onClick={() => {
                            setAgeCheck(false);
                            setError({ status: false, title: "", message: "" });
                          }}
                        >
                          Go Back
                    </button>
                      </div>
                    </>
                  ) : (
                      ""
                    )}

                  <p>
                    Already registered?{" "}
                    <Link to="/sign-in" className="text-blue text-d-none">
                      Sign in here
                  </Link>
                  </p>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Age;
