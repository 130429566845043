import React, { useState, useRef, useEffect } from "react";
import "../../../App.scss";
import Slider from "react-slick";
import coronaVirusTitle from "../../../assets/pages/coronavirus-head.png";
import bloodtestTitle from "../../../assets/pages/blood-test-head.png";
import musicTitle from "../../../assets/pages/music-head.png";
import pickmixTitle from "../../../assets/pages/pick-n-mix-head.png";
import readySteadyTitle from "../../../assets/pages/ready-steady-go-head.png";
import error from "../../../assets/image/error.png";
import xrayTitle from "../../../assets/pages/x-ray-head.png";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
import VideoUploader from "../../../components/uploader/VideoUploader";
import ImageUploader from "../../../components/uploader/ImageUploader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addVideoService,
  deleteVideoService,
  getVideoByCategory,
  updateVideoService,
} from "../../../services/video.services";
import { userInfo } from "../../../services";
import Select from "react-select";
import Iframe from "react-iframe";
import { getRequestIp } from "../../../services/request-ip";
const { detect } = require("detect-browser");
const options = [
  { value: "video", label: "Video" },
  { value: "link", label: "Link" },
];
const videoOptions = [
  { value: "url", label: "Video URL" },
  { value: "upload", label: "Upload file" },
];
const iframeUrl = `${process.env.REACT_APP_PLAYCANV_IFRAME}`;

const browser = detect();
interface IContentModel {
  id?: string;
  title: string;
  category: string;
  type: string;
  thumb_img: string;
  video_url: string;
}

function HasbroContent(props: any) {
  const search = useLocation().search;
  const userWifiConnected = new URLSearchParams(search).get(
    "userWifiConnected"
  );
  let [showVideo, toggleVideo] = useState(false);
  // let [loading, setLoading] = useState(false);
  // let [isMute, setMute] = useState(true);
  let [videoUrl, setVideoUrl] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  let sliderRef = useRef(null);
  let [addVideo, setAddVideo] = useState(false);
  let [editContent, setEditContent] = useState(false);
  let [isAdmin, setIsAdmin] = useState(false);
  const [uploadVideo, setUploadVideo] = useState(String);
  const [uploadVideoImage, setUploadVideoImage] = useState(String);
  const [uploadVideoTitle, setUploadVideoTitle] = useState(String);
  const [uploadType, setUploadType] = useState(String);
  const [selectedItemId, setSelectedItemId] = useState(Number);
  let [videosList, setVideosList] = useState<any>([]);
  // let [activeChildren, setActiveChildren] = useState<number>();
  let [deleteVideoArray, setDeleteVideoArray] = useState<any>({
    title: "",
    id: "",
    type: "",
  });
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "Chose media type",
  });
  const [selectedVideoOption, setSelectedVideoOption] = useState({
    value: "",
    label: "",
  });
  // const [videoMode, setVideoMode] = useState(['upload', 'url']);
  let [category, setCategory] = useState(String);

  var settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
    ],
  };

  // useEffect(() => {
  //     let currentChild = childrens.filter(
  //         (child: any) => child.isActive);
  //     if (currentChild.length > 0) {
  //         let activeChild = currentChild[0];
  //         if (!selectedChildSkin) {
  //             setSelectedChildSkin(activeChild.currentTheme);
  //             localStorage.setItem("skin", activeChild.currentTheme);
  //         }
  //         if (!selectedChild) {
  //             setSelectedChild(activeChild.id)
  //         }
  //     }
  // }, [childrens, selectedChild, selectedChildSkin, videoUrl]);

  const backButton = async () => {
    await getRequestIp()
      .then((results) => {
        if (category !== "hasbro") {
          if (localStorage.getItem("token")) {
            window.location.href = `${iframeUrl}?token=${localStorage.getItem(
              "token"
            )}&environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${
              results.data.requestIp
            }&startPage=Learn`;
          } else {
            window.location.href = `${iframeUrl}?environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${results.data.requestIp}&startPage=Learn`;
          }
        } else {
          if (localStorage.getItem("token")) {
            window.location.href = `${iframeUrl}?token=${localStorage.getItem(
              "token"
            )}&environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${
              results.data.requestIp
            }&startPage=My Stuff scene 2`;
          } else {
            window.location.href = `${iframeUrl}?environment=${process.env.REACT_APP_CUSTOM_NODE_ENV}&requestIp=${results.data.requestIp}&startPage=My Stuff scene 2`;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addVideoList = async () => {
    let videoItem: any = {
      title: uploadVideoTitle,
      video_url: uploadVideo,
      thumb_img: uploadVideoImage,
      type: selectedOption.value,
      category,
    };

    if (editContent) videoItem.id = selectedItemId;

    if (!editContent) {
      await addVideoService(videoItem)
        .then((response) => {
          if (response) {
            let addVideo = [response.data.data, ...videosList];
            setVideosList(addVideo);
            setAddVideo(false);
            toast(response.data.message);
          }
          // updateSliderSlide(videosList.length + 1);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await updateVideoService(videoItem)
        .then((response) => {
          if (response) {
            let updateItemIndex = videosList.findIndex(
              (video: IContentModel) => video.id === videoItem.id
            );
            let removedItem = videosList.filter((video: IContentModel) => {
              return video.id !== videoItem.id;
            });
            removedItem.splice(updateItemIndex, 0, videoItem);
            setVideosList(removedItem);
            toast(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setEditContent(false);
    }

    clearForm();
    // setUploadVideo('');
    // setUploadVideoImage('');
    // setUploadVideoTitle('');
    // setVideoMode(['upload', 'url'])
  };

  const deleteVideo = async (event: any, id: Number) => {
    await deleteVideoService(id)
      .then((response) => {
        let removeVideo = videosList.filter((video: any) => video.id !== id);
        setDeletePopup(false);
        setEditContent(false);
        toast(response.data.message);
        setVideosList(removeVideo);
        clearForm();
        // updateSliderSlide(removeVideo.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let thePath = window.location.href;
    const lastItem = thePath.substring(thePath.lastIndexOf("/") + 1);
    if (lastItem.includes("hasbro")) {
      setCategory("hasbro");
      if (userWifiConnected) {
        localStorage.setItem("wi-boolean", userWifiConnected);
      }
    } else {
      setCategory(lastItem);
    }

    // localStorage.setItem('wi-boolean', )
    getVideoByCategory(lastItem)
      .then((response) => {
        if (response && response.data.videos) {
          console.log(response.data.videos);

          setVideosList(response.data.videos);
          // updateSliderSlide(response.data.videos.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // if (lastItem && lastItem !== 'hasbro') {
    userInfo().then((response) => {
      if (response.role === '1') setIsAdmin(true);
    });
    // }
  }, []);

  // function updateSliderSlide(length: number) {

  // }

  const clearForm = () => {
    setUploadVideo("");
    setUploadVideoImage("");
    setUploadVideoTitle("");
    // setVideoMode(['upload', 'url']);
    setSelectedOption({
      value: "",
      label: "Chose media type",
    });
  };

  // useEffect(() => {
  //   getChildrens().then((res) => {
  //     if (res && res.children.length > 0) {
  //       let child = res.children.filter(
  //         (children: any) => children.isActive === true
  //       );
  //       setActiveChildren(child[0]["id"]);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (!editContent) {
      setVideoUrl("");
    }
  }, [selectedOption]);

  return (
    <>
      <div className={category && `Background video ${category}`}>
        <div className="shade"></div>
      </div>
      {(addVideo || editContent) && (
        <div className="popup-overlay d-block">
          <div className="popup-container mx-auto">
            <div>
              <div className="w-100 d-flex">
                <h2 className="mt-5 mb-4 pl-5 w-100">
                  {editContent ? "Edit" : "Add"} your content
                </h2>
                <div
                  onClick={() => {
                    setAddVideo(false);
                    setEditContent(false);
                    clearForm();
                  }}
                >
                  <div className="close-popup m-2"></div>
                </div>
              </div>
              {/* {((editContent && uploadVideoTitle) || addVideo) && ( */}
              {(editContent || addVideo) && (
                <div className="px-3 px-md-5 pb-4">
                  <div className="hasbro-label w-100 text-left row mx-0">
                    <label htmlFor="">Add your title</label>
                    <input
                      className="add-video-title mb-10 col-11"
                      name="title"
                      placeholder="Enter title"
                      autoComplete="off"
                      value={uploadVideoTitle}
                      onChange={(e) => {
                        setUploadVideoTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="hasbro-label w-100 text-left row mx-0">
                    <label htmlFor="">Select your media type</label>
                    <Select
                      className="add-video-title mb-10 col-11 fix-select"
                      classNamePrefix="remove-border-select"
                      placeholder="Choose media type"
                      value={selectedOption}
                      onChange={(e: any) => {
                        setSelectedOption(e);
                      }}
                      options={options}
                    />
                  </div>

                  {selectedOption.value === "video" && (
                    <div className="hasbro-label w-100 text-left row mx-0">
                      <label htmlFor="">Select your video source</label>
                      <Select
                        className="add-video-title mb-10 col-11 fix-select"
                        classNamePrefix="remove-border-select"
                        placeholder="Select one"
                        onChange={(e: any) => {
                          setSelectedVideoOption(e);
                        }}
                        value={selectedVideoOption}
                        options={videoOptions}
                      />
                    </div>
                  )}

                  {selectedOption.value === "video" &&
                    selectedVideoOption &&
                    selectedVideoOption.value === "upload" && (
                      <div className="w-100 text-left mb-1">
                        <div>
                          <div className="hasbro-label w-100 text-left mb-1">
                            <label className="d-block" htmlFor="">
                              Upload your video file
                            </label>
                            <p className="mb-1 mt-0 text-small text-cust-dark">
                              We support .mp4/.webm/.mov video formats. Maximum
                              upload file size is 250 MB.
                            </p>
                          </div>
                          <div>
                            <VideoUploader
                              // setVideo={setVideoMode}
                              videoURL={setUploadVideo}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {selectedOption.value === "video" &&
                    selectedVideoOption &&
                    selectedVideoOption.value === "url" && (
                      <div className="w-100 text-left mb-1">
                        <div className="hasbro-label w-100 text-left mb-1">
                          <label className="d-block" htmlFor="enter_url">
                            Enter the Video URL
                          </label>
                          <input
                            id="enter_url"
                            className="add-video-title mb-1 col-10 col-md-11"
                            name="title"
                            placeholder="Enter URL"
                            value={uploadVideo}
                            autoComplete="off"
                            onChange={(e) => {
                              if (e.target.value.length) {
                                // setVideoMode(['url']);
                                setUploadVideo(e.target.value);
                              } else {
                                // setVideoMode(['upload', 'url']);
                                setUploadVideo("");
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}

                  {selectedOption.value === "link" && (
                    <div className="hasbro-label w-100 text-left mb-1">
                      <label className="d-block" htmlFor="enter_url">
                        Enter website Link
                      </label>
                      <input
                        id="enter_url"
                        className="add-video-title mb-1 col-10 col-md-11"
                        name="title"
                        value={uploadVideo}
                        placeholder="Enter website link"
                        autoComplete="off"
                        onChange={(e) => {
                          if (e.target.value.length) {
                            // setVideoMode(['url']);
                            setUploadVideo(e.target.value);
                          } else {
                            // setVideoMode(['upload', 'url']);
                            setUploadVideo("");
                          }
                        }}
                      />
                    </div>
                  )}

                  {(selectedOption.value === "video" ||
                    selectedOption.value === "link") && (
                    <>
                      <div className="hasbro-label w-100 text-left my-1">
                        <label className="d-block" htmlFor="">
                          Upload your thumbnail
                        </label>
                        <p className="mb-1 mt-0 text-small text-cust-dark">
                          We support .jpeg/.jpg/.png image formats. Maximum
                          upload file size is 1 MB.
                        </p>
                      </div>
                      <div className="mb-2">
                        {editContent && (
                          <div className="text-left">
                            <img
                              src={uploadVideoImage}
                              alt="Thumb"
                              height="100px"
                            />
                          </div>
                        )}
                        <ImageUploader imageURL={setUploadVideoImage} />
                      </div>
                    </>
                  )}
                  <button
                    className="curve-btn btn-back mb-10 no-shawdow col-12 col-md-7 justify-content-center align-items-center"
                    type="button"
                    disabled={
                      !uploadVideo ||
                      !uploadVideoImage ||
                      !uploadVideoTitle ||
                      !selectedOption.value
                    }
                    onClick={() => addVideoList()}
                  >
                    {editContent ? "Update" : "Add content"}
                  </button>
                  {editContent && (
                    <div
                      className="text-d-none action link-a text-danger"
                      onClick={() => {
                        setDeletePopup(true);
                        setDeleteVideoArray({
                          title: uploadVideoTitle,
                          id: selectedItemId,
                        });
                      }}
                    >
                      Delete content
                    </div>
                  )}
                </div>
              )}
              {/* <button
                                className="curve-btn btn-back mb-10 no-shawdow col-12 d-flex w-100 justify-content-center align-items-center"
                                type="button"
                                onClick={() => setAddVideo(false)}>
                                Go back
                            </button> */}
            </div>
          </div>
        </div>
      )}

      <div className="slider-container">
        {category !== "hasbro" && (
          <div className="page-title">
            <img
              className="mt-2"
              src={
                category === "blood-test"
                  ? bloodtestTitle
                  : category === "x-ray"
                  ? xrayTitle
                  : category === "coronavirus"
                  ? coronaVirusTitle
                  : category === "ready-steady-go"
                  ? readySteadyTitle
                  : category === "music"
                  ? musicTitle
                  : category === "pick-n-mix"
                  ? pickmixTitle
                  : ""
              }
              alt={`category`}
            />
          </div>
        )}
        {showVideo && (
          <div className="close-container">
            <a
              href="javascript:void(0)"
              onClick={() => {
                setVideoUrl("");
                toggleVideo(false);
              }}
            >
              <div className="cloud-close-button" />
            </a>
          </div>
        )}
        {!showVideo && (
          <a onClick={backButton}>
            <div className="back-button"></div>
          </a>
        )}
        {videosList.length > 0 && (
          <div id="video-slider" className="w-100 p-0 mt-xs-1 mt-sm-1 mt-md-5">
            <Slider {...settings} ref={sliderRef}>
              {videosList.map((item: any, key: any) => (
                <>
                  <div
                    className="card-block skins-card d-flex justify-content-center"
                    key={key}
                    onClick={(event) => {
                      setTimeout(() => {
                        setUploadType(item.type);
                        setVideoUrl(item.video_url);
                        toggleVideo(true);
                      }, 1000);
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <div
                        className={
                          browser.name === "chrome"
                            ? "card-wrapper-chrome"
                            : "card-wrapper"
                        }
                      ></div>
                      <img
                        className={
                          browser.name === "chrome"
                            ? "card-container-chrome"
                            : "card-container-safari"
                        }
                        src={"images/card_container.png"}
                        alt=""
                      />
                      {/* <img className="video-card" src={item.thumb_img} alt="" /> */}
                      <div
                        className="video-card"
                        style={{
                          backgroundImage: "url(" + item.thumb_img + ")",
                          backgroundColor: "gray",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <div className="playContainer "></div>
                      <div
                        className={
                          item.type === "link"
                            ? "playButton link"
                            : "playButton"
                        }
                      ></div>
                      {isAdmin && (
                        <div className="slider-title">
                          {/* {item.name}{" "} */}
                          <div
                            className="trashSetup"
                            onClick={(e) => {
                              let selectedValue = options.filter(
                                (opt) => opt.value === item.type
                              );
                              setSelectedOption(selectedValue[0]);
                              setEditContent(true);
                              setSelectedItemId(item.id);
                              setUploadVideoTitle(item.title);
                              setSelectedVideoOption(videoOptions[0]);
                              setUploadVideo(item.video_url);
                              setUploadVideoImage(item.thumb_img);

                              // setDeletePopup(true);
                              // setDeleteVideoArray({
                              //     title: item.title,
                              //     id: item.id,
                              //     type: item.type
                              // })
                              e.stopPropagation();
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                    {/* <div className="video-active slider-card d-flex justify-content-center" style={{ backgroundImage: "url(" + item.thumbnail + ")", backgroundSize: '100% 100%' }}>
                                    <div className="playContainer"></div>
                                    <div className="playButton"></div>
                                </div> */}
                  </div>
                  {item.title && (
                    <div className="card-block skins-card d-flex justify-content-center">
                      <div className=" card-container-chrome-text">
                        <div className="d-flex justify-content-center ">
                          <div className="video-label">{item.title}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </Slider>
          </div>
        )}
      </div>

      {isAdmin && (
        <div
          className="add-video-bottom cursor-pointer"
          onClick={() => setAddVideo(true)}
        >
          <img src="/images/icon_addXL@2x.png" alt="" />
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showVideo && (
        <div className="player-wrapper">
          {uploadType === "link" && (
            <Iframe
              url={videoUrl}
              width="100%"
              height="100%"
              loading="eager"
              className="iframe-website"
              allow="fullscreen"
              position="absolute"
            />
          )}
          {uploadType === "video" && (
            <ReactPlayer
              className="react-player"
              config={{
                file: {
                  attributes: { controlsList: "nodownload", playsinline: true },
                },
                youtube: {
                  playerVars: { showinfo: 1, autoplay: 1, controls: 1 },
                },
              }}
              url={videoUrl}
              width="100%"
              height="100%"
              controls
              playing
              muted={true}
              autoplay
              playsinline
            />
          )}
        </div>
      )}

      {deletePopup && (
        <div className="popup-overlay">
          <div className="popup-container p-3">
            <img src={error} alt="Delete Child" />
            <h2 className="text-blue mtb-0">Are you sure ?</h2>
            <p>
              Are you sure you want to delete {deleteVideoArray.title} video?
            </p>
            <div>
              <button
                className="curve-btn btn-delete mb-10 no-shawdow"
                type="button"
                onClick={(e) => deleteVideo(e, deleteVideoArray.id)}
              >
                Delete Content
              </button>
            </div>
            <div>
              <button
                className="curve-btn btn-back mb-10 no-shawdow"
                type="button"
                onClick={() => setDeletePopup(false)}
              >
                Go back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function PrevArrow(props: any) {
  const { onClick, currentSlide } = props;
  return (
    <div className={currentSlide > 0 ? "d-block" : "d-none"}>
      <div onClick={onClick}>
        <div className="slide-left-container"></div>
      </div>
    </div>
  );
}

function NextArrow(props: any) {
  const { onClick, slideCount, currentSlide } = props;
  return (
    <div
      className={
        slideCount - currentSlide > (window.innerWidth > 600 ? 2 : 1)
          ? "d-block"
          : "d-none"
      }
    >
      <div onClick={onClick}>
        <div className="slide-right-container"></div>
      </div>
    </div>
  );
}

export default HasbroContent;
