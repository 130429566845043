import { baseEnv, headerToken } from "../environments/base";
import axios from "axios";
import { handleResponseStatus } from "./responseHandler";
import {
  CheckForgotTokenModel,
  ResetPasswordModel,
  VerifyEmailModel,
} from "../models/user.model";

export function login(email: string, password: string) {
  return axios
    .post(`${baseEnv.api.login}`, {
      email: email,
      password: password,
    })
    .then(handleResponse)
    .then((response) => {
      localStorage.setItem("token", response.token);
      if (response.role) {
        localStorage.setItem("userRole", response.role);
      }
      return response.token;
    })
    .catch((error) => {
      handleResponseStatus(error);
      return Promise.reject(error.response.data.message);
    });
}

export function register(payload: any) {
  console.log("payload~~~~~~~~~~~~~~~", payload);
  return axios
    .post(`${baseEnv.api.register}`, {
      name: payload.name,
      mobile: payload.mobile,
      email: payload.email,
      password: payload.password,
      termsAndCondtion: payload.termsAndCondtion,
    })
    .then(handleResponse)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleResponseStatus(error.response);
      return Promise.reject(error.response.data.message);
    });
}

// export function newPassword(payload) {
//   return axios
//     .post(`${baseEnv.api.newPassword}`, {
//       token: payload.token,
//       password: payload.password,
//       confirmPassword: payload.confirmPassword,
//     })
//     .then(handleResponse)
//     .then(function (response) {
//       return response.data;
//     })
//     .catch(function (error) {
//       handleResponseStatus(error.response);
//       return Promise.reject(error);
//     });
// }

export function logout() {
  localStorage.setItem("skin", "skinA");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
}

export function checkUser(token: string) {
  return axios({
    method: "get",
    url: `${baseEnv.api.check}`,
    headers: {
      Authorization: "Bearer " + token,
      Pragma: "no-cache",
    },
  })
    .then(function (response) {
      console.log(response);
      if (response && response.data.status) {
        const user = response.data.data[0];
        let userData = {
          ...user,
        };
        localStorage.setItem("roles", userData.roles[0]);
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("token", token);
        return user;
      }
      return undefined;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function userInfo() {
  return axios({
    method: "get",
    url: `${baseEnv.api.userInfo}`,
    headers: {
      Authorization: headerToken(),
      Pragma: "no-cache",
    },
  })
    .then(function (response) {
      if (response && response.data) {
        console.log(response.data.user);
        return response.data.user;
      }
      return undefined;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function forgotPassword(props: any) {
  return axios
    .post(`${baseEnv.api.forgotPassword}`, {
      email: props.email,
    })
    .then(handleResponse)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function checkForgotToken(props: CheckForgotTokenModel) {
  return axios
    .post(`${baseEnv.api.checkForgotPassToken}`, {
      token: props.token,
    })
    .then(handleResponse)
    .then(function (response) {
      return response;
    })
    .catch((error) => {
      handleResponseStatus(error.response);
      return Promise.reject(error.response.data.message);
    });
}

export function resetPassword(props: ResetPasswordModel) {
  return axios
    .post(`${baseEnv.api.resetPassword}`, props)
    .then(handleResponse)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function verifyEmail(props: VerifyEmailModel) {
  return axios
    .post(`${baseEnv.api.verifyEmail}`, props)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      handleResponseStatus(error.response);
      return Promise.reject(error.response.data.message);
    });
}

function handleResponse(response: any) {
  console.log(response);
  if (response.status === 401) {
    //logout();
  } else if (response.status === 200 && response.data.status === false) {
    const error =
      (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  } else {
    return response.data;
  }
}
