import { history } from "../utils/history";

export function handleResponseStatus(response: any) {
    switch (response.status) {
        case 503:
            // history.push('/') //we will redirect user into 503 page 
            console.log("response.status, response.data.message")
            break
        case 401:
            console.log(response.status, response.data.message);
            localStorage.clear();
            history.push('/sign-in')
            break
        case 500:
            console.log(response.status, response.data.message);
            history.push('/internal-server-error')
            break
        default:
            break
    }
}
