import React, { createContext, useReducer, useEffect, useContext } from "react";
import { history } from "../utils/history";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";

//services
import * as UserService from "../services/user.services";

//Reducers
import * as UserReducer from "../stores/reducers/users.reducer";
import * as AuthReducer from "../stores/reducers/authentication.reducer";

//Actions
import * as userActions from "../stores/actions/user.action";

// Tina CMS
import { useCMS } from "tinacms";
import TinaAuthContext from "./TinaAuthContext";

export type AppContextInterface = {
  authState: any;
  userRegState: any;
  handleAuth: any;
  registerUser: any;
  logout: any;
};

//Create Auth Context
export const AuthContext = createContext({} as AppContextInterface);

export const AuthProvider = ({ children }: any) => {

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();


  const cms = useCMS();
  const { toggleTinaAdminAuthenticated, changeTinaAdminUserData } =
    useContext(TinaAuthContext);

  const [authState, dispatchAuthState] = useReducer(
    AuthReducer.authentication,
    AuthReducer.initialState
  );
  const [userRegistration, dispatchRegistration] = useReducer(
    UserReducer.usersReducer,
    UserReducer.initialState
  );

  useEffect(() => {
    if (localStorage.getItem("token") && authState.loggedIn === true) {
      // console.log("User Logged In");
    }
  }, [authState]);

  // login
  function authenticate(props: any) {
    dispatchAuthState(userActions.requestLogin());
    UserService.login(props.email, props.password).then(
      (user) => {
        if (localStorage.getItem("userRole") == 'admin') {
          cms.enable();
          toggleTinaAdminAuthenticated();
          changeTinaAdminUserData();
        }
        dispatchAuthState(userActions.successLogin(user));
        history.replace("/childrens");
      },
      (error) => {
        if (error) {
          dispatchAuthState(userActions.failureLogin(error.toString()));
        }
      }
    );
  }

  // User registration
  function registerUser(props: any) {
    dispatchRegistration(userActions.requestRegister());
    UserService.register(props).then(
      (user) => {
        dispatchRegistration(userActions.successRegister(user));
      },
      (error) => {
        dispatchRegistration(userActions.failureRegister(error));
      }
    );
  }

  function handleLogOut() {
    if (isAuthenticated) {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      }).then(() => {
        dispatchAuthState(userActions.logout());
        history.push("/");
      })
    } else {
      dispatchAuthState(userActions.logout());
      history.push("/");
    }
  }

  return (
    <AuthContext.Provider
      value={{
        authState: authState,
        userRegState: userRegistration,
        registerUser: (props: any) => registerUser(props),
        handleAuth: (props: any) => authenticate(props),
        logout: () => handleLogOut(),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
