import { baseEnv, headerToken } from "../environments/base";
import axios from "axios";
import { handleResponseStatus } from "./responseHandler"

export function addVideoService(payload) {
  return axios
    .post(
      `${baseEnv.api.addVideo}`,
      payload,
      {
        headers: {
          Authorization: headerToken(),
          Pragma: 'no-cache'
        }
      })
    // .then(handleResponse)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function updateVideoService(payload) {
  return axios
    .put(
      `${baseEnv.api.updateVideo}`,
      payload,
      {
        headers: {
          Authorization: headerToken(),
          Pragma: 'no-cache'
        }
      })
    // .then(handleResponse)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function deleteVideoService(id) {
  return axios
    .delete(
      `${baseEnv.api.deleteVideo}/${id}`,
      {
        headers: {
          Authorization: headerToken(),
          Pragma: 'no-cache'
        }
      })
    // .then(handleResponse)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

export function getVideoByCategory(category) {
  return axios
    .get(
      `${baseEnv.api.getVideoCategory}/${category}`,
      {
        headers: {
          Authorization: headerToken(),
          Pragma: 'no-cache'
        }
      })
    // .then(handleResponse)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      handleResponseStatus(error.response);
      return Promise.reject(error);
    });
}

// function handleResponse(response) {
//   console.log(response);
//   if (response.status === 401) {
//     //logout();
//   } else if (response.status === 200 && response.data.status === false) {
//     const error =
//       (response.data && response.data.message) || response.statusText;
//     return Promise.reject(error);
//   } else {
//     return response.data;
//   }
// }
