import { userConstants } from "../../constants";
import * as userService from "../../services/user.services";

export function requestLogin() {
  return {
    type: userConstants.LOGIN_REQUEST,
  };
}

export function successLogin(user: string) {
  return {
    type: userConstants.LOGIN_SUCCESS,
    user: user,
  };
}

export function failureLogin(error: string) {
  return {
    type: userConstants.LOGIN_FAILURE,
    message: error,
  };
}

export function requestRegister() {
  return {
    type: userConstants.REGISTER_REQUEST,
  };
}

export function failureRegister(error: string) {
  console.log(error);

  return {
    type: userConstants.REGISTER_FAILURE,
    message: error,
  };
}

export function successRegister(user: string) {
  return {
    type: userConstants.REGISTER_SUCCESS,
    user: user,
  };
}

export function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}
