import { childConstants, userConstants } from "../../constants";

export const initialState = {
  childrens: [],
  hasbroFlag: false
};

export function childReducer(state = initialState, action: any) {
  switch (action.type) {
    case childConstants.ADD_CHILDREN:
      return {
        ...state,
        childrens: [action.payload, ...state.childrens],
      };
    case childConstants.HASBRO_CHILDREN:
        return {
          ...state,
          hasbroFlag: action.payload,
        };
    case childConstants.GET_CHILDREN:
      return {
        ...state,
        childrens: action.childrens.children,
      };
    case childConstants.UPDATE_CHILDREN:
      const updatedChild = action.payload;

      const updatedChildrens = state.childrens.map((child: any) => {
        if (child.id === updatedChild.id) {
          return updatedChild;
        }
        return child;
      });

      return {
        ...state,
        childrens: updatedChildrens,
      };

    case childConstants.DELETE_CHILDREN:
      return {
        ...state,
        childrens: state.childrens.filter(
          (child: any) => child.id !== action.payload
        ),
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        childrens: initialState.childrens,
      };
    default:
      return state;
  }
}
