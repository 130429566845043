import { childConstants } from "../../constants";
import { ChildrenDetailsAddModel, ChildrenDetailsUpdateModel, ChildListArrayModel } from "../../models/child.model"


export function addChild(child: ChildrenDetailsAddModel) {
  return {
    type: childConstants.ADD_CHILDREN,
    child: child,
  };
}

export function updateChild(child: ChildrenDetailsUpdateModel) {
  return {
    type: childConstants.UPDATE_CHILDREN,
    payload: child,
  };
}

export function deleteChild(child: number) {
  return {
    type: childConstants.DELETE_CHILDREN,
    child: child,
  };
}

export function getChild(childrens: ChildListArrayModel) {
  return {
    type: childConstants.GET_CHILDREN,
    childrens: childrens,
  };
}
