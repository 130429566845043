import skinDefault from "../assets/image/hills-bg.png";
import skinSkyline from "../assets/image/skyline-bg.png";
import skinBeach from "../assets/image/beach-bg.png";
import skinSpace from "../assets/image/space-bg.png";
import skinDefaultRight from "../assets/image/Right-i.png";
import skinDefaultLeft from "../assets/image/Left-i.png";
import skinSkylineRight from "../assets/image/Right-skyline-i.png";
import skinSkylineLeft from "../assets/image/Left-skyline-i.png";
import skinBeachRight from "../assets/image/Right-beach-i.png";
import skinBeachLeft from "../assets/image/Left-beach-i.png";


interface SkinThemesModel {
  [key: string]: keyNew
}

interface keyNew {
  // "background-img": string
  // "arrow-img-right": string
  // "arrow-img-left": string
  // "btn-primary": string
  // "btn-secondary": string
  // "btn-action": string
  // "text-color": string
  [key: string]: string
}


// interface valuePair {
//   background-img

// }


const SkinThemes: SkinThemesModel = {
  skinA: {
    // background-img for app
    "background-img": `url(${skinDefault})`,
    // Arrow-img for app
    "arrow-img-right": `url(${skinDefaultRight})`,
    "arrow-img-left": `url(${skinDefaultLeft})`,
    // buttons for skin
    "btn-primary": "#ff9011, #ffb81e",
    "btn-secondary": "#1a4584, #6983F0",
    "btn-action": "#000000",
    // server/404 error text color
    "text-color": "#1a4584"
  },
  skinB: {
    // background-img for app
    "background-img": `url(${skinSkyline})`,
    // Arrow-img for app
    "arrow-img-right": `url(${skinSkylineRight})`,
    "arrow-img-left": `url(${skinSkylineLeft})`,
    // buttons for skin
    "btn-primary": "#C860BF, #EBB4E7",
    "btn-secondary": "#000000, #1A4584",
    "btn-action": "#ffffff",
    // server/404 error text color
    "text-color": "#ffffff"
  },
  skinC: {
    // background-img for app
    "background-img": `url(${skinBeach})`,
    // Arrow-img for app
    "arrow-img-right": `url(${skinBeachRight})`,
    "arrow-img-left": `url(${skinBeachLeft})`,
    // buttons for skin
    "btn-primary": "#EEC672, #CBB040",
    "btn-secondary": "#23B2A0, #24796F",
    "btn-action": "#000000",
    // server/404 error text color
    "text-color": "#1a4584"
  },
  skinD: {
    // background-img for app
    "background-img": `url(${skinSpace})`,
    // Arrow-img for app
    "arrow-img-right": `url(${skinSkylineRight})`,
    "arrow-img-left": `url(${skinSkylineLeft})`,
    // buttons for skin
    "btn-primary": "#19B4D8, #6CCBE2",
    "btn-secondary": "#346EDB, #231480",
    "btn-action": "#ffffff",
    // server/404 error text color
    "text-color": "#ffffff"
  },
};

export default SkinThemes;
