import React from 'react'
import logo from '../../../assets/image/alderplaylogo.png';
import { Link } from 'react-router-dom';

function WelcomeLogo() {
    return (
        <div className="hero-container">
            <h2>Welcome to</h2>
            <Link to="/"><img src={logo} alt="Logo" /></Link>
        </div>
    )
}

export default WelcomeLogo
