import { baseEnv, headerToken } from "../environments/base";
import axios from "axios";
import { handleResponseStatus } from "./responseHandler";

const addEvent = (data: any) => {
    try {
        return axios.post(baseEnv.api.addUserEvent, data, {
            headers: {
                Authorization: headerToken(),
            },
        });
    } catch (error: any) {
        handleResponseStatus(error.response);
        return Promise.reject(error.response.data.message);
    }
};

export {
    addEvent
};
