import React from "react";

function CardStatusMessage(props: any) {
  return (
    <div className="message-box mb-10">
      <h2 className="text-blue mtb-0">{props.title}</h2>
      <p className="mtb-0">{props.message}</p>
    </div>
  );
}

export default CardStatusMessage;
