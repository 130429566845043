import React from 'react';

import { BlocksControls, InlineTextarea } from 'react-tinacms-inline';

export interface H3Props {
  index: number;
}

const H3: React.SFC<H3Props> = (data: { index: number }) => {
  return (
    <BlocksControls index={data.index} focusRing={{ offset: 0 }} insetControls>
      <div>
        <h3>
          <InlineTextarea name="text" focusRing={false} />
        </h3>
      </div>
    </BlocksControls>
  );
};

export default H3;

export const h3Block = {
  Component: H3,
  template: {
    label: 'H3',
    defaultItem: {
      text: 'H3 Title'
    },
    fields: []
  }
};
